.fe-container {
  margin-top: 40px;
  flex:       1;
  //min-height: 100vh;
  width:      100%;
  @include DisplayFlexCustom(column, flex-start, center);

  .text-body {
    @include DisplayFlexCustom(column, flex-start, center);
    position: relative;
    height:   auto;
    width:    60%;
    padding:  2rem 6rem;
    margin:   3rem 0;
  }

  .box {
    margin-bottom: 1rem;
  }

  .side-title {
    position:                  absolute;
    writing-mode:              vertical-rl;
    text-orientation:          upright;
    font-family:               HACKED, HACKED-Cyr, sans-serif;
    font-style:                normal;
    font-weight:               normal;
    font-size:                 5rem;
    line-height:               100%;
    /* or 100px */
    letter-spacing:            0.05em;
    text-shadow:               2px 0 0 #29e0e1, -2px 0 0 #29e0e1, 0 2px 0 #29e0e1, 0 -2px 0 #29e0e1, 1px 1px #29e0e1, -1px -1px 0 #29e0e1, 1px -1px 0 #29e0e1, -1px 1px 0 #29e0e1;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #29e0e1;
  }

  .side-title.left {
    top:  5rem;
    left: 0;
  }

  .side-title.right {
    right:  0;
    bottom: 0;
  }

  .left-text {
    align-self: flex-start;
  }

  .right-text {
    align-self: flex-end;
  }

  h1 {
    align-self:     flex-start;
    font-family:    MontserratBold, sans-serif;
    font-style:     normal;
    font-weight:    900;
    font-size:      3rem;
    line-height:    100%;
    /* identical to box height, or 50px */
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-bottom:  1rem;
  }

  h2 {
    font-family:               MontserratBlack, sans-serif;
    font-style:                normal;
    font-weight:               900;
    font-size:                 3rem;
    line-height:               100%;
    /* identical to box height, or 50px */
    letter-spacing:            0.05em;
    text-transform:            uppercase;
    text-shadow:               2px 0 0 var(--font-color), -2px 0 0 var(--font-color), 0 2px 0 var(--font-color), 0 -2px 0 var(--font-color), 1px 1px var(--font-color), -1px -1px 0 var(--font-color), 1px -1px 0 var(--font-color), -1px 1px 0 var(--font-color);
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #FFFFFF;
    align-self:                flex-end;
    margin-bottom:             2rem;
  }

  .box {
    width:         60%;
    margin-bottom: 5rem;
    @include DisplayFlex;
    align-items:   flex-end;
  }

  .right-side-img {
    position: absolute;
    top:      12rem;
    right:    20%;
  }

  .fileImg {
    margin: 0 2rem;
    width:  5rem;
  }

  .titles {
    width:          70%;
    align-self:     flex-start;
    @include DisplayFlex;
    flex-direction: column;

    h1 {
      align-self: flex-start;
    }

    h2 {
      align-self: flex-end;
    }
  }

  .side-number {
    writing-mode:              vertical-rl;
    text-orientation:          upright;
    font-style:                normal;
    font-weight:               normal;
    font-size:                 10rem;
    line-height:               100%;
    font-family:               MontserratBlack, sans-serif;
    /* or 100px */
    letter-spacing:            0.05em;
    text-shadow:               2px 0 0 #29e0e1, -2px 0 0 #29e0e1, 0 2px 0 #29e0e1, 0 -2px 0 #29e0e1, 1px 1px #29e0e1, -1px -1px 0 #29e0e1, 1px -1px 0 #29e0e1, -1px 1px 0 #29e0e1;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #29e0e1;
    color:                     black;
  }

  h3, h4 {
    font-style:                normal;
    font-family:               MontserratBlack, sans-serif;
    font-weight:               normal;
    font-size:                 2rem;
    line-height:               100%;
    /* or 100px */
    letter-spacing:            0.05em;
    text-shadow:               0.5px 0 0 #29e0e1, -0.5px 0 0 #29e0e1, 0 0.5px 0 #29e0e1, 0 -0.5px 0 #29e0e1, 0.5px 0.5px #29e0e1, -0.5px -0.5px 0 #29e0e1, 0.5px -0.5px 0 #29e0e1, -0.5px 0.5px 0 #29e0e1;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: #29e0e1;
    color:                     black;
    align-self:                flex-start;
    margin-bottom:             3rem;
  }

  .groupPeopleImg {
    width:  15rem;
    height: 15.7rem;
  }

  .personImg {
    width:  13rem;
    height: 15rem;
  }

  .squareImg, .square1Img {
    width:  5rem;
    height: 57rem;
  }

  .locationImg {
    width:  11rem;
    height: 16rem;
  }

  .unionImg {
    width:  22rem;
    height: 76rem;
  }

  .eyeImg {
    width:  14rem;
    height: 14rem;
  }

  .listImg {
    width:  12rem;
    height: 16rem;
  }
}

/////////////////////////////////////////
.fe-entrance {
  .second-text {
    width:      100%;
    margin-top: 15rem;
  }

  .right-side-img {
    right:  10%;
    width:  55rem;
    height: 20rem;
  }

  .left-side-img {
    float:        left;
    margin-right: 10rem;
    margin-left:  2rem;
  }
}

.fe-international {
  .third-text {
    width: 100%;
  }

  .img3 {
    width:  30rem;
    height: 30rem;
  }

  .seventh-text {
    position: relative;
  }

  .squareImg {
    position: absolute;
    bottom:   0;
    left:     -8rem;
  }
}

.fe-national {
  .left-side-img {
    position: absolute;
    left:     -15rem;
    bottom: 5rem;
  }
  .second-text{
    position: relative;
  }

  .side-title.right {
    top: 30%;
  }

  .img4 {
    position: absolute;
    left:     0;
    width:    62rem;
    height:   17rem;
  }

  .squareImg {
    position: absolute;
    left:     0;
  }

  .img5 {
    position: absolute;
    right:    5rem;
    width:    18rem;
    height:   15rem;
  }

  .square1Img {
    position: absolute;
    left:     0;
  }

  .unionImg {
    position: absolute;
    right:    0;
  }
}

.fe-2020 {
  .left-side-img {
    left:     15%;
    position: absolute;
  }

  .side-title.first {
    top: 10%;
  }

  .side-title.second {
    top: 20%;
  }

  .side-title.third {
    top: 40%;
  }

  .side-title.fourth {
    top: 55%;
  }

  .side-title.fifth {
    top: 70%;
  }
}

.fe-recommendations {
  .square1Img {
    position: absolute;
    right:    0;
    bottom:   10%;
  }

  .right-side-img {
    top: 6rem;
  }

  .box {
    align-items: flex-start;
  }
}

@media only screen and (max-width: 1024px) {
  .fe-container {
    .text-body {
      width: 90%;
    }
  }
  .fe-international {
    .squareImg {
      display: none;
    }
  }
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 540px) {
  .fe-container {
    .right-side-img {
      position: static;
      float:    right;
    }

    .fileImg {
      display: none;
    }

    .box {
      width:    100%;
      position: relative;
    }

    .side-title {
      display: none;
    }

    .text-body {
      padding: 4rem 2rem;
    }

    .titles {
      width: 100%;

      h1, h2 {
        align-self: flex-start;
      }
    }

    .squareImg {
      display: none;
    }
  }
  .fe-entrance {
    .right-side-img {
      display: none;
    }

    .left-side-img {
      float: left;
      margin: 0;
    }

    .second-text {
      margin:         0;
      flex-direction: column;
      text-align: end;
    }
  }
  .fe-international {
    h1, h2 {
      font-size: 2.5rem;
    }

    .img3 {
      display: none;
    }
  }
  .fe-national {
    .second-text{
      text-align: end;
    }
    h1, h2 {
      font-size: 2.5rem;
    }

    .left-side-img {
      position: static;
      float: left;
    }

    .img4, .img5, .img6, .squareImg, .square1Img, .unionImg {
      display: none;
    }
  }
  .fe-2020 {
    .left-side-img {
      position: static;
      float:    left;
    }
    .second-text{
      text-align: end;
    }
  }
  .fe-recommendations {
    .square1Img {
      display: none;
    }

    .side-number {
      font-size: 5rem;
    }
  }
}
