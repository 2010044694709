html {
  font-size: 14px;
}

body,
#root {
  width: 100%;
  height: 100%;
  background: var(--background-color);
}

body {
  //overflow: hidden;
  //overflow-y: auto;
}

.market-application {
  margin-left: auto;
  margin-right: auto;
}

table {
  width: 100%;
}

input,
select {
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

p, span {
  color: var(--font-color);
}

.banner {
  background: var(--background-color);
}

.titles h1 {
  color: var(--font-color);
}

.file-span {
  color: #ffffff;
}

.dg-entrance .second-text{
  p {
    color: #ffffff;
  }
}

.theme-dark {
  --color-primary: #17ed90;
  --color-secondary: #243133;
  --color-accent: #12cdea;

  --font-color: #FFFFFF;
  --background-color: #000000;
  .sun{
    display: inline-block;
  }
  .moon{
    display: none;
  }
}

.theme-light {
  --color-primary: #0060df;
  --color-secondary: #fbfbfe;
  --color-accent: #fd6f53;

  --font-color: #000000;
  --background-color: #FFFFFF;

  .left-side-img, .squareImg,.personImg,
  .fe-national .img5,.locationImg,.groupPeopleImg,.right-side-img,
  .square2, .dg-entrance .second-text, .long,.lockIcon {
    filter: invert(100%);
  }

  .sun{
    display: none;
  }
  .moon{
    display: inline-block;
  }
}

.theme-button {
  position: fixed;
  top: 20px;
  right: 50px;
  color: var(--font-color);
  z-index: 101;
}

.App {
  height: 100vh;
}

.scroll-to-top {
  display: none;
  position: fixed;
}

.container {
  @include DisplayFlexCustom(column, start, center);
  min-height: 100%;
  width: 100%;
  max-width: 1920px;
  align-self: center;
  margin: 0 auto;
}

.box {
  font-family: MontserratSemiBold, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 150%;
  /* or 22px */
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--font-color);
}

.go-back {
  position: fixed;
  border: 2px solid var(--font-color);
  padding: 0.8rem 2rem;
  color: var(--font-color);
  left: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 100;
}

.languages-links {
  position: fixed;
  right: 180px;
  bottom: 20px;
  z-index: 101;

  span {
    cursor: pointer;
    margin-right: 1rem;
    text-decoration: none;
    font-weight: 300;
    font-family: MontserratLight, sans-serif;
    font-style: normal;
    font-size: 1rem;
    line-height: 150%;
    /* identical to box height, or 22px */
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--font-color);
    //:hover, :active, :visited {
    //  color: #ffffff;
    //}
  }

  span:hover,
  a:active,
  a:visited,
  a:link {
    color: var(--font-color);
  }

  span.active {
    font-family: MontserratBold, sans-serif;
    font-style: normal;
    font-weight: bold;
  }
}

.top-nav-wrapper {
  height: auto;
  position: relative;
}

.menu-bar {
  @include DisplayFlexCustom(row, space-between, center);
  align-self: center;
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0);

  img {
    width: 4rem;
    height: 4rem;
  }
}

.top-navs {
  //opacity: 0;
  width: 598.63px;
  height: 96px;
  align-items: flex-start;
  transition: 0.3s linear;

  img {
    width: 65px !important;
    height: 65px !important;
    margin-bottom: 35px;
    margin-right: 0;
    cursor: pointer;
    transition: 0.3s linear;
  }

  img:hover {
    opacity: 0;
    transition: 0.3s linear;
  }
}

.top-navs.false {
  opacity: 1;
  background-image: none;
  background-size: 100% 100%;
  transition: 0.3s linear;
}

.top-navs.one {
  opacity: 1;
  background-image: url("../../images/menu-page/navs/1.svg");
  background-size: 100% 100%;
  transition: 0.3s linear;
}

.top-navs.two {
  background-image: url("../../images/menu-page/navs/2.svg");
  background-size: 100% 100%;
  transition: 0.3s linear;
}

.top-navs.three {
  background-image: url("../../images/menu-page/navs/3.svg");
  background-size: 100% 100%;
  transition: 0.3s linear;
}

.top-navs.four {
  background-image: url("../../images/menu-page/navs/4.svg");
  background-size: 100% 100%;
  transition: 0.3s linear;
}

.top-navs.five {
  background-image: url("../../images/menu-page/navs/5.svg");
  background-size: 100% 100%;
  transition: 0.3s linear;
}

.top-navs.six {
  background-image: url("../../images/menu-page/navs/6.svg");
  background-size: 100% 100%;
  transition: 0.3s linear;
}

.top-navs.seven {
  background-image: url("../../images/menu-page/navs/7.svg");
  background-size: 100% 100%;
  transition: 0.3s linear;
}

.top-navs.eight {
  background-image: url("../../images/menu-page/navs/8.svg");
  background-size: 100% 100%;
  transition: 0.3s linear;
}

.top-nav-wrapper {
  position: relative;
}

.nav-title {
  position: absolute;
  color: #000000;
  font-weight: 600;
  font-size: 1.2rem;
  font-family: "MontserratSemiBold", sans-serif;
  opacity: 0;
}

.img-row {
  position: relative;

  span {
    opacity: 0;
  }
}

.img-row.img-one:hover {
  span {
    opacity: 1;
  }
}

.img-row.img-two:hover {
  span {
    opacity: 1;
  }
}

.img-row.img-three:hover {
  span {
    opacity: 1;
  }
}

.img-row.img-four:hover {
  span {
    opacity: 1;
  }
}

.img-row.img-five:hover {
  span {
    opacity: 1;
  }
}

.img-row.img-six:hover {
  span {
    opacity: 1;
  }
}

.img-row.img-seven:hover {
  span {
    opacity: 1;
  }
}

.img-row.img-eight:hover {
  span {
    opacity: 1;
  }
}

.nav-title.left {
  position: absolute;
  //opacity: 1;
  left: 0;
  bottom: 0;
  margin-left: 70px;
  margin-bottom: 10px;
  width: 230px;
  text-align: center;
}

.nav-title.right {
  position: absolute;
  //opacity: 1;
  right: 70px;
  bottom: 0;
  margin-bottom: 10px;
  width: 230px;
  text-align: center;
}

.nav-title.four {
  //margin-left: 20px !important;
  font-size: 1rem;
}

//.nav-title.four.four{
//  position: absolute;
//  left: 300px;
//  bottom: 0;
//  margin-left: 70px;
//  margin-bottom: 5px;
//  width: 230px;
//  text-align: center;
//}
//.nav-title.five.five{
//  position: absolute;
//  left: 300px;
//  bottom: 0;
//  margin-left: 70px;
//  margin-bottom: 5px;
//  width: 230px;
//  text-align: center;
//}
//.nav-title.six.six{
//  position: absolute;
//  left: 300px;
//  bottom: 0;
//  margin-left: 70px;
//  margin-bottom: 5px;
//  width: 230px;
//  text-align: center;
//}
.file {
  position: relative;
  @include CenterWithDisplayFlex;
}

.file {
  position: relative;
  align-self: flex-end;
}

.file-span {
  width: 66px !important;
  height: 44px !important;
  @include CenterWithDisplayFlex;
  z-index: 1001;
}

.left-text {
  .file {
    margin-left: 10px;
  }
}

.right-text {
  .file {
    margin-right: 10px;
  }
}

.fileEmpty {
  position: absolute;
  margin: 0 !important;
  padding: 0;
  background-image: url("../../images/file-img.svg");
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
}

.side-icons {
  display: none;
}

.lower-part {
}

.disclaimer__links {
  position: fixed;
  bottom: 20px;
  right: 20px;
  @include DisplayFlex;
  align-items: flex-end;
}

.ext-img-link {
  img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    margin-right: 5px;
  }
}

.disclaimer__button {
  text-decoration: none;
  color: var(--font-color);
  margin-right: 10px;

  :hover,
  :visited,
  :active {
    color: var(--font-color);
  }
}

.disclaimer__container {
  @include CenterWithDisplayFlex;
  flex-direction: column;
  font-size: 2rem;
  color: var(--font-color);
  width: 40%;
  height: 100%;
  margin: 0 auto;
  text-align: center;

  p {
    margin-bottom: 40px;

    a {
      color: var(--font-color);

      :hover, :active {
        color: var(--font-color);
      }
    }
  }
}

.main-page .digital-privacy {
  right: 5% !important;
}

.main-page .rights-to-fair-trial {
  right: 25% !important;
}

.main-page .info-access {
  left: 5% !important;
}

.main-page .limitation-on-internet-content {
  right: 5% !important;
}

.main-page .new-calls {
  left: 10% !important;
}

.main-page .freedom-expression {
  right: 25% !important;
  bottom: 13% !important;
}

.lang-file {
  color: var(--font-color) !important;

  :hover, :active {
    color: var(--font-color) !important;
  }

  position: absolute;
  bottom: 30px;
  left: 50px;
}

@media only screen and (max-width: 1768px) {
  .main-page .digital-privacy {
    right: 5% !important;
  }
  .main-page .rights-to-fair-trial {
    right: 25% !important;
  }
  .main-page .info-access {
    left: 5% !important;
  }
  .main-page .limitation-on-internet-content {
    right: 5% !important;
  }
  .main-page .new-calls {
    left: 10% !important;
  }
  .main-page .freedom-expression {
    right: 25% !important;
    bottom: 13% !important;
  }
}

@media only screen and (max-width: 1536px) {
  html {
    font-size: 12px;
  }
  .languages-links {
    //top: 20px;
    //bottom: 30px;
  }
}

@media only screen and (max-width: 1440px) {
  .languages-links {
    //right: 18%;
  }
  .main-page .digital-privacy {
    right: 5% !important;
  }
  .main-page .rights-to-fair-trial {
    right: 25% !important;
  }
  .main-page .info-access {
    left: 5% !important;
  }
  .main-page .limitation-on-internet-content {
    right: 5% !important;
  }
  .main-page .new-calls {
    left: 10% !important;
  }
  .main-page .freedom-expression {
    right: 25% !important;
    bottom: 13% !important;
  }
}

@media only screen and (max-width: 1366px) {
  html {
    font-size: 11px;
  }
  .languages-links {
    //top: 20px;
    right: 160px;
    //bottom: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .side-icons {
    display: initial;
    text-decoration: none;

    .MuiSvgIcon-root {
      color: #29e0e1;
      font-size: 5rem;
    }
  }
  .menu-page .menu-title h1 {
    font-size: 3.6rem !important;
  }
  .top-nav-wrapper {
    display: none;
  }
  .menu-page .menu-title {
    @include CenterWithDisplayFlex;
    background-image: none !important;

    h1 {
      background-image: url("../../../assets/images/menu-page/title-back.svg");
      background-size: 100% 100%;
    }
  }
}

@media only screen and (max-width: 1024px) {
}

.res-title {
  color: var(--font-color);
  font-size: 2rem;
  font-family: HACKED, HACKED-Cyr, sans-serif, sans-serif;
  text-align: center;
  vertical-align: middle;
  margin: 40px 0;
}

.resources {
  width: 66px !important;
  height: 44px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  position: fixed;
  bottom: 30px;
  right: 50px;
  cursor: pointer;

  &__row {
    @include DisplayFlex;
    width: 80%;
    justify-content: flex-start;
  }

  &__number {
    font-style: normal;
    font-family: MontserratBlack, sans-serif;
    font-weight: normal;
    font-size: 2rem;
    line-height: 100%;
    letter-spacing: 0.05em;
    text-shadow: 0.5px 0 0 #29e0e1, -0.5px 0 0 #29e0e1, 0 0.5px 0 #29e0e1,
    0 -0.5px 0 #29e0e1, 0.5px 0.5px #29e0e1, -0.5px -0.5px 0 #29e0e1,
    0.5px -0.5px 0 #29e0e1, -0.5px 0.5px 0 #29e0e1;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: #29e0e1;
    color: black;
    align-self: flex-start;
    margin-bottom: 3rem;
    margin-right: 10px;
  }

  &__link {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    font-style: normal;
    font-family: MontserratSemiBold, sans-serif;
    font-weight: normal;
    font-size: 1rem;
    line-height: 100%;
    letter-spacing: 0.05em;
    align-self: flex-start;
    margin-bottom: 3rem;
    color: var(--font-color);
  }
}

@media only screen and (max-width: 812px) {
  .languages-links {
    top: 20px;
    right: 15px;

    span {
      margin-bottom: 10px;
      font-size: 1.5rem;
    }
  }
  .resources {
    bottom: 30px;
    right: 15px;
  }

  .theme-button{
    left: 120px;
    right: initial;
    top: 25px;
  }
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 10px;
  }
  .main-page .personal-security {
    left: 30% !important;
    top: 20% !important;
  }
  .main-page .info-access {
    left: 10% !important;
  }
  .main-page .digital-privacy {
    right: 10% !important;
  }
  .main-page .freedom-expression {
    bottom: 30% !important;
  }
  .main-page__title {
    height: 70% !important;
  }
  .main-page {
    justify-content: flex-start !important;
  }

}

@media screen and (max-width: 540px) {
  .disclaimer__container {
    .go-back {
      font-size: 9px !important;
      padding: 0.8rem 2rem;
    }
  }
  .disclaimer {
    &__links {
      position: static;
      justify-content: space-evenly;
    }

    &__button {
      font-size: 2rem;
    }
  }
  .nav-buttons,
  .five-navs {
    height: auto !important;
  }
  .disclaimer__container {
    width: 100%;
    padding: 10rem 4rem;
    margin: auto;
    text-align: center;
  }
  .resources {
    top: 20px;
    left: 15px;
    width: 50px !important;
    height: 33px !important;

    &__number,
    &__link {
      margin-bottom: 10px;
    }

    &__number {
      width: 15%;
      margin-right: 0;
    }

    &__link {
      width: 85%;
    }
  }
  .file-span {
    width: 50px !important;
    height: 33px !important;
    @include CenterWithDisplayFlex;
    z-index: 1001;
  }
  .text-body {
    padding: 5rem 2rem !important;
    width: 90% !important;
  }
  .scroll-to-top {
    display: initial;
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 101;
  }
  .MuiSvgIcon-root {
    color: var(--font-color);
    font-size: 5rem !important;
  }
  .right-container {
    min-height: auto !important;
  }
  .container > .nav-buttons {
    flex-direction: column;
    height: auto;
    width: 90%;
    padding-bottom: 50px;

    span {
      position: static;
      height: 50px;
      width: 100%;
      align-items: center;
      padding: 0;
    }
  }
  .container > .five-navs {
    flex-direction: column;
    height: auto;
    width: 90%;
    padding-bottom: 50px;

    .smaller-text {
      position: static;
      height: 50px;
      width: 100%;
      align-items: center;
      padding: 0;
    }
  }
  .button-one,
  .button-one:hover {
    background-image: url("../../images/button-one-long.svg");
    margin-bottom: 10px;
    color: var(--font-color);
  }
  .button-same,
  .button-same:hover {
    background-image: url("../../images/button-nav-same.svg");
    margin-bottom: 10px;
    color: var(--font-color);
  }
  .menu-page .menu-title h1 {
    font-size: 2rem !important;
  }

  .theme-button{
    bottom: 20px;
    left: 20px;
    top: initial;
    .MuiSvgIcon-root{
      font-size: 2rem !important;
    }
  }
}

@media screen and (max-width: 576px) {
  .lang-file {
    position: initial;
  }
}

@media only screen and (max-width: 410px) {
  html {
    font-size: 9px;
  }
}

@media screen and (max-width: 320px) {
  .menu-page .menu-title h1 {
    font-size: 1.5rem !important;
  }
}
