.dr-container {
  margin-top: 40px;
  flex:       1;
  min-height: 100vh;
  width:      100%;
  @include DisplayFlexCustom(column, flex-start, center);

  .text-body {
    @include DisplayFlexCustom(column, flex-start, center);
    position: relative;
    height:   auto;
    width:    50%;
    padding:  2rem 6rem 6rem 6rem;
    margin:   3rem 0;
  }

  .box {
    margin-bottom: 1rem;
  }

  .side-title {
    position:                  absolute;
    writing-mode:              vertical-rl;
    text-orientation:          upright;
    font-family:               HACKED, HACKED-Cyr, sans-serif;
    font-style:                normal;
    font-weight:               normal;
    font-size:                 5rem;
    line-height:               100%;
    /* or 100px */
    letter-spacing:            0.05em;
    text-shadow:               2px 0 0 #29e0e1, -2px 0 0 #29e0e1, 0 2px 0 #29e0e1, 0 -2px 0 #29e0e1, 1px 1px #29e0e1, -1px -1px 0 #29e0e1, 1px -1px 0 #29e0e1, -1px 1px 0 #29e0e1;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #29e0e1;
  }

  .side-title.left {
    top:  5rem;
    left: 0;
  }

  .side-title.right {
    right:  0;
    bottom: 10%;
  }

  .left-text {
    align-self: flex-start;
  }

  .right-text {
    align-self: flex-end;
  }

  h1 {
    align-self:     flex-start;
    font-family:    MontserratBold, sans-serif;
    font-style:     normal;
    font-weight:    900;
    font-size:      3rem;
    line-height:    100%;
    /* identical to box height, or 50px */
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-bottom:  1rem;
  }

  h2 {
    font-family:               MontserratBlack, sans-serif;
    font-style:                normal;
    font-weight:               900;
    font-size:                 3rem;
    line-height:               100%;
    /* identical to box height, or 50px */
    letter-spacing:            0.05em;
    text-transform:            uppercase;
    text-shadow:               2px 0 0 var(--font-color), -2px 0 0 var(--font-color), 0 2px 0 var(--font-color), 0 -2px 0 var(--font-color), 1px 1px var(--font-color), -1px -1px 0 var(--font-color), 1px -1px 0 var(--font-color), -1px 1px 0 var(--font-color);
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #FFFFFF;
    align-self:                flex-end;
    margin-bottom:             2rem;
  }
  .right-side-img {
    position: absolute;
    top:0;
    right: -50%;
  }
  .left-side-img {
    position: absolute;
    width: 100%;
    top:0;
    left: -50%;
  }

  .groupPeopleImg {
    width: 15rem;
    height: 15.7rem;
  }

  .personImg {
    width: 13rem;
    height: 15rem;
  }

  .squareImg, .square1Img {
    width: 5rem;
    height: 57rem;
  }

  .locationImg {
    width: 11rem;
    height: 16rem;
  }

  .unionImg {
    width: 22rem;
    height: 76rem;
  }

  .lockIcon {
    width: 14rem;
    height: 14rem;
  }

  .listImg {
    width: 12rem;
    height: 16rem;
  }


}

///////////////////////////////// ВВЕДЕНИЕ ///////////////////////////////
.dg-entrance {
  .first-text {
    width:      60%;
    align-self: flex-start;
    position: relative;
    .right-side-img{
      top:0;
      right: -50%;
    }

    h1 {
      font-family:    MontserratBlack, sans-serif;
      font-style:     normal;
      font-weight:    900;
      font-size:      3rem;
      line-height:    150%;
      /* identical to box height, or 75px */
      letter-spacing: 0.05em;
      text-transform: uppercase;
    }
  }

  .second-text {
    width:            36.4rem;
    height:           14rem;
    align-self:       flex-end;
    background-image: url("../../images/digital-rights/Group 32.svg");
    background-size:  100% 100%;
    padding:          1rem 2rem 1rem 4rem;
  }


  .third-text {
    width:      31rem;
    //height:     16rem;
    align-self: flex-start;

    h3 {
      margin-left: 4rem;
      margin-top:  0.5rem;
      border:     2px solid #29e0e1;
    }

    p {
      padding: 1.5rem 2rem 1rem 3.5rem;
      border:     2px solid #29e0e1;
    }
  }

  .fourth-text {
    width:      60%;
    align-self: flex-end;

    position: relative;
  }

  .small-desc {
    font-family:    MontserratBoldItalic, sans-serif;
    font-style:     italic;
    font-weight:    bold;
    font-size:      1.5rem;
    line-height:    150%;
    /* identical to box height, or 37px */
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-bottom:  1rem;
  }
}

///////////////////////////////// WHAT IS IT ///////////////////////////////
.dr-what {
.first-text,.second-text{
  position: relative;
}
  .left-side-img{
    left: -100%;
  }
  .box {
    width: 60%;
    margin-bottom: 5rem;
  }
}

///////////////////////////////// АВТОРЫ ///////////////////////////////
.dr-authors {
  h2 {
    margin-right: 20rem;
  }

  .box {
    margin-bottom: 5rem;
  }
  .first-text{
    position: relative;
  }

  .table {
    @include DisplayFlexCustom(column, center, center);

    .table-row {
      @include DisplayFlexCustom(row, flex-start, center);
      width: 100%;
    }

    .left-col {
      font-family:    MontserratSemiBold, sans-serif;
      font-style:     normal;
      font-weight:    600;
      font-size:      1.5rem;
      line-height:    150%;
      /* identical to box height, or 30px */
      letter-spacing: 0.1em;
      text-transform: uppercase;
      width:          40%;
      text-align:     start;
    }

    .right-col {
      font-family:    MontserratMedium, sans-serif;
      font-style:     normal;
      font-weight:    500;
      font-size:      1rem;
      line-height:    150%;
      /* or 22px */
      letter-spacing: 0.1em;
      text-transform: uppercase;
      width:          60%;
    }
  }

  .box {
    width: 60%;
  }
}

///////////////////////////////// ВЫВОДЫ ///////////////////////////////
.dr-results {
  .first-text{
    position: relative;
  }
  .right-middle {
    position: absolute;
    top:      25%;
    right:    0;
  }

  .left-bottom {
    position: absolute;
    top:      50%;
    left:     0;
  }

  .box {
    width: 60%;
  }

  .third-text {
    border: 2px solid #29e0e1;
    padding:          2rem;
  }
}

@media only screen and (max-width: 1536px) {
  .dg-entrance {
    .fourth-text {
      width: 60%;
    }

    p {
      font-size: 0.9rem;
    }
  }
}

@media only screen and (max-width: 1536px) {
  .dr-results {
    .third-text {
      padding: 1rem;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .dr-container {
    .text-body {
      width:      80%;
      min-height: 90vh;
      padding:    4rem 6rem;
    }
  }
}

@media only screen and (max-width: 540px) {
  .dg-entrance .third-text h3{
    padding: 0.5rem 2rem;
  }
  .dr-container {
    min-height: auto;
    .box {
      background-image: none;
      width:            100%;
      margin-bottom:    4rem;
      padding: 0;
      height: auto;
    }

    .text-body {
      width:   90%;
      min-height: auto;
    }
    .side-title{
      display: none;
    }
    .text-body{
      padding: 0;
    }
    .right-side-img {
      position: static;
      float: right;
    }
    .left-side-img{
      position: static;
      float: left;
    }
    .long{
      display: none;
    }
  }
  .dg-entrance {
    .third-text {
      height: auto;
    }
    .fourth-text{
      text-align: end;
    }

  }

  .dr-authors {
    h2 {
      margin-right: 0;
    }

    .table-row {
      flex-direction:  column !important;
      justify-content: flex-start !important;
      align-items:     flex-start !important;
      margin-bottom:   2rem;
    }

    .left-col, .right-col {
      width: 100% !important;
    }
  }
}
