html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  box-sizing: border-box;
  vertical-align: baseline;
  background: transparent;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
  color: #FFF;
  font: 14px Arial, Helvetica, sans-serif;
}

:focus {
  outline: 0;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  height: 100%;
}

input {
  border: none;
  outline: none;
  background: unset;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}

input::-ms-clear {
  display: none;
}

button, button:active {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
}

select {
  border: none;
}





