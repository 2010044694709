.right-container {
  margin-top: 40px;
  flex:       1;
  min-height: 100vh;
  width:      100%;
  @include DisplayFlexCustom(column, flex-start, center);

  .text-body {
    @include DisplayFlexCustom(column, flex-start, center);
    position: relative;
    height:   auto;
    width:    60%;
    padding:  2rem 6rem;
    margin:   3rem 0;
  }

  .box {
    margin-bottom: 1rem;
  }

  .side-title {
    position:                  absolute;
    writing-mode:              vertical-rl;
    text-orientation:          upright;
    font-family:               HACKED, HACKED-Cyr, sans-serif;
    font-style:                normal;
    font-weight:               normal;
    font-size:                 5rem;
    line-height:               100%;
    /* or 100px */
    letter-spacing:            0.05em;
    text-shadow:               2px 0 0 #29e0e1, -2px 0 0 #29e0e1, 0 2px 0 #29e0e1, 0 -2px 0 #29e0e1, 1px 1px #29e0e1, -1px -1px 0 #29e0e1, 1px -1px 0 #29e0e1, -1px 1px 0 #29e0e1;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #29e0e1;
  }

  .side-title.left {
    top:  5rem;
    left: 0;
  }

  .side-title.right {
    right:  0;
    bottom: 10%;
  }

  .left-text {
    align-self: flex-start;
  }

  .right-text {
    align-self: flex-end;
  }

  h1 {
    align-self:     flex-start;
    font-family:    MontserratBold, sans-serif;
    font-style:     normal;
    font-weight:    900;
    font-size:      3rem;
    line-height:    100%;
    /* identical to box height, or 50px */
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-bottom:  1rem;
  }

  h2 {
    font-family:               MontserratBlack, sans-serif;
    font-style:                normal;
    font-weight:               900;
    font-size:                 3rem;
    line-height:               100%;
    /* identical to box height, or 50px */
    letter-spacing:            0.05em;
    text-transform:            uppercase;
    text-shadow:               2px 0 0 var(--font-color), -2px 0 0 var(--font-color), 0 2px 0 var(--font-color), 0 -2px 0 var(--font-color), 1px 1px var(--font-color), -1px -1px 0 var(--font-color), 1px -1px 0 var(--font-color), -1px 1px 0 var(--font-color);
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #FFFFFF;
    align-self:                flex-end;
    margin-bottom:             2rem;
  }

  .box {
    width:         60%;
    margin-bottom: 5rem;
  }

  .right-side-img {
    position: absolute;
    top:      12rem;
    right:    20%;
  }

  .fileImg {
    margin: 0 2rem;
    width:  5rem;
  }

  .titles {
    width:          70%;
    align-self:     flex-start;
    @include DisplayFlex;
    flex-direction: column;

    h1 {
      align-self: flex-start;
    }

    h2 {
      align-self: flex-end;
    }
  }

  .side-number {
    writing-mode:              vertical-rl;
    text-orientation:          upright;
    font-style:                normal;
    font-weight:               normal;
    font-size:                 5rem;
    line-height:               100%;
    font-family:               MontserratBlack, sans-serif;
    /* or 100px */
    letter-spacing:            0.05em;
    text-shadow:               2px 0 0 #29e0e1, -2px 0 0 #29e0e1, 0 2px 0 #29e0e1, 0 -2px 0 #29e0e1, 1px 1px #29e0e1, -1px -1px 0 #29e0e1, 1px -1px 0 #29e0e1, -1px 1px 0 #29e0e1;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #29e0e1;
    color:                     black;
  }

  h3, h4 {
    font-style:                normal;
    font-family:               MontserratBlack, sans-serif;
    font-weight:               normal;
    font-size:                 2rem;
    line-height:               100%;
    /* or 100px */
    letter-spacing:            0.05em;
    text-shadow:               0.5px 0 0 #29e0e1, -0.5px 0 0 #29e0e1, 0 0.5px 0 #29e0e1, 0 -0.5px 0 #29e0e1, 0.5px 0.5px #29e0e1, -0.5px -0.5px 0 #29e0e1, 0.5px -0.5px 0 #29e0e1, -0.5px 0.5px 0 #29e0e1;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: #29e0e1;
    color:                     black;
    align-self:                flex-start;
    margin-bottom:             3rem;
  }

  .box {
    @include DisplayFlex;
    align-items: flex-end;
  }
}

// Entrance
.right-entrance {

  .second-title {
    margin-top: 5rem;
  }
  .second-text{
    text-align: end;
  }

  .wrap-box {
    @include DisplayFlex;
    justify-content: flex-end;
    position:        relative;
  }

  .groupPeopleImg {
    margin-right: 5rem;
    position:     absolute;
    left:         0;
  }

  .img1 {
    width:  55rem;
    height: 28rem;
  }

  .right-side-img, .groupPeopleImg {
    width:  13rem;
    height: 15rem;
  }
  .side-title{
    font-size: 3rem;
    height: 500px;
  }
  .side-title.right{
    top: 10%;
  };
  .text-body{
    //min-height: 80%;
  }
}

// national
.right-national {
  .right-side-img {
    width:  11rem;
    height: 16rem;
  }
}

// 2020
.right-2020 {
  .second-text {
    width:           100% !important;
    justify-content: space-between;

    p {
      width: 60%;
    }
  }

  .side-title.first, {
    top: 10%;
  }

  .side-title.second {
    top: 30%;
  }

  .side-title.third, {
    top: 45%;
  }

  .side-title.fourth {
    top: 60%
  }

  .side-title.fifth {
    top: 75%
  }

  .left-side-img {
    width:  14rem;
    height: 14rem;
  }
}

// recommendations
.right-recommendations {
  .box {
    align-items: flex-start !important;
  }

  .right-side-img {
    top:    2rem;
    width:  12rem;
    height: 15.7rem;
  }
}

@media only screen and (max-width: 1024px) {
  .right-container {
    .text-body {
      width: 90%;
    }
  }
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 540px) {
  .right-container {
    .right-side-img {
      position: static;
      float:    right;
    }

    .fileImg {
      display: none;
    }

    .box {
      width:    100%;
      position: relative;
    }

    .side-title {
      display: none;
    }

    .text-body {
      padding: 4rem 2rem;
    }
  }
  // entrance
  .right-entrance {
    .img1 {
      display: none;
    }

    .right-side-img {
      float:    right;
      position: static;
    }

    .groupPeopleImg {
      position: static;
      float:    left;
    }

    .fileImg {
      display: none;
    }
  }
  // right 2020
  .right-2020 {
    .left-side-img {
      display: none;
    }

    .second-text {
      p {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 540px) {
}
