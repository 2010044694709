.ia-container {
  margin-top: 40px;
  flex: 1;
  //min-height: 100vh;
  width: 100%;
  @include DisplayFlexCustom(column, flex-start, center);

  .text-body {
    @include DisplayFlexCustom(column, flex-start, center);
    position: relative;
    height: auto;
    width: 60%;
    padding: 2rem 6rem;
    margin: 3rem 0;
  }

  .box {
    margin-bottom: 1rem;
  }

  .side-title {
    position: absolute;
    writing-mode: vertical-rl;
    text-orientation: upright;
    font-family: HACKED, HACKED-Cyr, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 4rem;
    line-height: 100%;
    /* or 100px */
    letter-spacing: 0.05em;
    text-shadow: 2px 0 0 #29e0e1, -2px 0 0 #29e0e1, 0 2px 0 #29e0e1, 0 -2px 0 #29e0e1, 1px 1px #29e0e1, -1px -1px 0 #29e0e1, 1px -1px 0 #29e0e1, -1px 1px 0 #29e0e1;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #29e0e1;
  }

  .side-title.left {
    top: 5rem;
    left: 0;
  }

  .side-title.right {
    right: 0;
    top: 5%;
  }

  .left-text {
    align-self: flex-start;
  }

  .right-text {
    align-self: flex-end;
  }

  h1 {
    align-self: flex-start;
    font-family: MontserratBold, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 3rem;
    line-height: 100%;
    /* identical to box height, or 50px */
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  h2 {
    font-family: MontserratBlack, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 3rem;
    line-height: 100%;
    /* identical to box height, or 50px */
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-shadow: 2px 0 0 var(--font-color), -2px 0 0 var(--font-color), 0 2px 0 var(--font-color), 0 -2px 0 var(--font-color), 1px 1px var(--font-color), -1px -1px 0 var(--font-color), 1px -1px 0 var(--font-color), -1px 1px 0 var(--font-color);
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #FFFFFF;
    align-self: flex-end;
    margin-bottom: 2rem;
  }

  .box {
    width: 60%;
    margin-bottom: 5rem;
    @include DisplayFlex;
    align-items: flex-end;
  }

  .right-side-img {
    position: absolute;
    top: 0;
    right: -50%;
  }

  .fileImg {
    margin: 0 2rem;
    width: 5rem;
  }

  .titles {
    width: 70%;
    align-self: flex-start;
    @include DisplayFlex;
    flex-direction: column;

    h1 {
      align-self: flex-start;
    }

    h2 {
      align-self: flex-end;
    }
  }

  .side-number {
    font-style: normal;
    font-weight: normal;
    font-size: 7rem;
    line-height: 100%;
    font-family: MontserratBlack, sans-serif;
    /* or 100px */
    letter-spacing: 0.05em;
    text-shadow: 2px 0 0 #29e0e1, -2px 0 0 #29e0e1, 0 2px 0 #29e0e1, 0 -2px 0 #29e0e1, 1px 1px #29e0e1, -1px -1px 0 #29e0e1, 1px -1px 0 #29e0e1, -1px 1px 0 #29e0e1;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #29e0e1;
    color: black;
  }

  h3, h4 {
    font-style: normal;
    font-family: MontserratBlack, sans-serif;
    font-weight: normal;
    font-size: 2rem;
    line-height: 100%;
    /* or 100px */
    letter-spacing: 0.05em;
    text-shadow: 0.5px 0 0 #29e0e1, -0.5px 0 0 #29e0e1, 0 0.5px 0 #29e0e1, 0 -0.5px 0 #29e0e1, 0.5px 0.5px #29e0e1, -0.5px -0.5px 0 #29e0e1, 0.5px -0.5px 0 #29e0e1, -0.5px 0.5px 0 #29e0e1;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: #29e0e1;
    color: black;
    align-self: flex-start;
    margin-bottom: 3rem;
  }

  .groupPeopleImg {
    width: 15rem;
    height: 15.7rem;
  }

  .personImg {
    width: 13rem;
    height: 15rem;
  }

  .squareImg, .square1Img {
    width: 5rem;
    height: 57rem;
  }

  .locationImg {
    width: 11rem;
    height: 16rem;
  }

  .unionImg {
    width: 22rem;
    height: 76rem;
  }

  .eyeImg {
    width: 14rem;
    height: 14rem;
  }

  .listImg {
    width: 12rem;
    height: 16rem;
  }
}

///////////////////////////////////////
.ia-entrance {
  .second-text {
    position: relative;

    p {
      border: 2px solid #29e0e1;
      padding: 1rem;
    }
  }

  .img1 {
    position: absolute;
    left: -25rem;
    width: 23rem;
    height: 23rem;
  }
  .first-text{
    position: relative;
  }
  .right-side-img {
    top: 0;
    right: -50%;
  }
}

.ia-international {
  .squareImg {
    position: absolute;
    left: 0;
  }
  .first-text{
    position: relative;
  }
}

.ia-national {
  .text6 {
    position: relative;
  }

  .img4 {
    position: absolute;
    top: 0;
    left: -25rem;
    width: 50rem;
    height: 25rem;
  }

  .unionImg {
    position: absolute;
    right: 0;
  }
}
.ia-national{
  .first-text{
    position: relative;
  }
}

.ia-2020 {
  .first-text {
    position: relative;
  }

  .right-side-img {
    top: 0;
    right: -50%;
  }

  .side-title.first {
    top: 10%;
  }

  .side-title.second {
    top: 20%
  }

  .side-title.third {
    top: 30%;
  }

  .side-title.fourth {
    top: 40%;
  }

  .side-title.fifth {
    top: 50%;
  }

  .side-title.sixth {
    top: 60%;
  }

  .side-title.seventh {
    top: 70%;
  }
}

.ia-recommendations {
  .box {
    align-items: flex-start;
  }

  .side-number {
    margin-right: 2rem;
  }

  .first-text {
    position: relative;
  }

  .listImg {
    top: 0;
    right: -20rem;
  }
}

///////////////////////////////////////
@media only screen and (max-width: 1024px) {
  .ia-container {
    .text-body {
      width: 90%;
    }
  }
  .ia-national {
    .img4 {
      display: none;
    }
  }
  .ia-entrance {
    align-items: center;
    justify-content: center;
    .side-title{
      //display: none;
      font-size: 4rem;
    }
  }
}

@media only screen and (max-width: 540px) {
  .ia-container {
    .right-side-img {
      position: static;
      float: right;
    }

    .fileImg {
      display: none;
    }

    .box {
      width: 100%;
      position: relative;
    }

    .side-title {
      display: none;
    }

    .text-body {
      padding: 4rem 2rem;
    }

    .titles {
      width: 100%;

      h1, h2 {
        align-self: flex-start;
      }
    }

    .squareImg, .unionImg {
      display: none;
    }
  }
}
