//@media only screen and (min-width: 320px) {
//  .menu-page {
//    width:  320px;
//    height: 568px;
//  }
//}
//
//@media only screen and (min-width: 375px) {
//  .menu-page {
//    width:  375px;
//    height: 812px;
//  }
//}
//
//@media only screen and (min-width: 540px) {
//  .menu-page {
//    width:  540px;
//    height: 720px;
//  }
//}
//
//@media only screen and (min-width: 768px) {
//  .menu-page {
//    width:  768px;
//    height: 1024px;
//  }
//}
//
//@media only screen and (min-width: 1024px) {
//  .menu-page {
//    width:  1024px;
//    height: 1366px;
//  }
//}
//
//@media only screen and (min-width: 1366px) {
//  .menu-page {
//    width:  1366px;
//    height: 768px;
//  }
//}
//
//@media only screen and (min-width: 1536px) {
//  .menu-page {
//    width:  1536px;
//    height: 864px;
//  }
//}
//
//@media only screen and (min-width: 1920px) {
//  .menu-page {
//    width:  1920px;
//    height: 1080px;
//  }
//}
.menu-page {
	//.nav-title {
	//  position: absolute;
	//  background-image: url("../../images/home-page/punkt.svg");
	//  background-size: 100% 100%;
	//  padding-top: 1.2rem;
	//  padding-left: 3rem;
	//  color: #000000;
	//  width: 20rem;
	//  height: 3rem;
	//}
	width: 100%;
	background: url("../../images/menu-page/back.svg") no-repeat center center;
	//object-fit:              cover;
	-webkit-background-size: contain;
	-moz-background-size: contain;
	-o-background-size: contain;
	background-size: contain;
	@include DisplayFlexCustom(column, flex-start, center);
	height: 100vh;
	max-height: 1080px;
	position: relative;
	max-width: 100vw;
	flex: 1;

	.about {
	}

	.nav-title1 {
		bottom: -3rem;
		left: 2rem;
	}

	.nav-title2 {
		bottom: -3rem;
		left: 7rem;
	}

	.nav-title3 {
		bottom: -3rem;
		left: 12rem;
	}

	.menu-title {
		width: 61rem;
		height: 6.6rem;
		background-image: url("../../../assets/images/menu-page/title-back.svg");
		background-size: 100% 100%;
		max-width: 100%;

		h1 {
			padding: 1rem;
			font-size: 4.6rem;
			font-family: HACKED, sans-serif;
			font-style: normal;
			font-weight: normal;
			line-height: 80%;
			text-align: center;
			letter-spacing: 0.05em;
			text-transform: uppercase;
			color: #000000;
		}
	}

	.menu-body {
		@include DisplayFlex;
		flex: 1;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		//background:     red;
		margin-top: 166px;
		position: relative;
		width: 80%;
		height: 70%;
		margin-bottom: 92px;
	}

	.upper-part {
		@include DisplayFlex;
		padding: 1rem;
	}

	.text-boxes {
		@include DisplayFlex;
		flex-direction: column;
		flex: 1;
		justify-content: space-between;
	}

	.side-word {
		margin-top: 1rem;
		writing-mode: vertical-rl;
		text-orientation: upright;
		font-family: HACKED, sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 5rem;
		line-height: 100%;
		/* or 100px */
		letter-spacing: 0.05em;
		text-shadow: 2px 0 0 #29e0e1, -2px 0 0 #29e0e1, 0 2px 0 #29e0e1,
			0 -2px 0 #29e0e1, 1px 1px #29e0e1, -1px -1px 0 #29e0e1,
			1px -1px 0 #29e0e1, -1px 1px 0 #29e0e1;
		-webkit-text-stroke-width: 2px;
		-webkit-text-stroke-color: #29e0e1;
	}

	.left-box {
		width: 35.7rem;
		height: 200px;

		.sub-page-title {
			font-family: MontserratBlack, sans-serif;
			font-style: normal;
			font-weight: 900;
			font-size: 3.5rem;
			line-height: 150%;
			/* identical to box height, or 75px */
			letter-spacing: 0.05em;
			text-transform: uppercase;
			color: #ffffff;
		}

		p {
			font-family: MontserratSemiBold, sans-serif;
			font-style: normal;
			font-weight: 600;
			font-size: 1rem;
			line-height: 150%;
			/* or 22px */
			letter-spacing: 0.05em;
			text-transform: uppercase;
			color: #ffffff;
		}
	}

	.right-box.empty {
		background-image: none !important;
	}

	.right-box {
		width: 37.5rem;
		height: 15.7rem;
		//margin-right:     3rem;
		margin-top: 2rem;
		padding-left: 3rem;
		justify-self: flex-end;
		align-self: flex-end;
		background-image: url("../../../assets/images/menu-page/right-box.svg");
		background-size: 100% 100%;

		p {
			padding: 2rem;
			font-family: MontserratSemiBold, sans-serif;
			font-style: normal;
			font-weight: 600;
			font-size: 1rem;
			line-height: 150%;
			/* or 22px */
			letter-spacing: 0.05em;
			text-transform: uppercase;
			color: #ffffff;
			width: 33rem;
		}
	}

	.small-desc {
		font-family: MontserratBoldItalic, sans-serif;
		font-style: italic;
		font-weight: bold;
		font-size: 1.7rem;
		line-height: 150%;
		/* identical to box height, or 37px */
		letter-spacing: 0.05em;
		text-transform: uppercase;
		margin-left: 3rem;
		margin-top: 0rem;
		color: #ffffff;
		justify-self: flex-end;
	}

	.side-button {
		top: 35%;
		position: absolute;
		@include CenterWithDisplayFlex;
		width: 10%;
		height: 30%;
		z-index: 100;
	}

	.side-button.left {
		left: 7%;
	}

	.side-button.right {
		right: 7%;
	}

	//  Lower part
	.lower-part {
		position: relative;
		@include DisplayFlex;
		align-self: center;
		flex-direction: row;
		//margin-left: 20rem;
	}
}

.button-one {
	background-image: url("../../icons/menu-page/entrance.svg");
	background-size: 100% 100%;
}

.button-one:hover {
	background-image: url("../../icons/menu-page/entrance-hover.svg");
}

.button-same {
	background-image: url("../../icons/menu-page/authors.svg");
	background-size: 100% 100%;
}

.button-same:hover {
	background-image: url("../../icons/menu-page/authors-hover.svg");
}

.nav-buttons {
	@include DisplayFlex;
	justify-content: space-between;
	width: 40rem;
	height: 3rem;
	position: fixed;
	margin-bottom: 50px;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 0);

	span {
		height: 100%;
		width: 25%;
		position: absolute;
		@include CenterWithDisplayFlex;
		font-family: MontserratMedium, sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 1rem;
		line-height: 150%;
		/* identical to box height, or 22px */
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: #ffffff;
		cursor: pointer;
	}

	span:hover {
		//color:       #000000;
		font-family: MontserratBold, sans-serif;
		font-weight: bold;
	}

	.entrance {
		left: 0;
		z-index: 10;
	}

	.what-is-it {
		left: 23%;
		z-index: 11;
	}

	.authors {
		z-index: 10;
		left: 45%;
	}

	.conclusion {
		left: 67%;
		z-index: 9;
	}
}

.five-navs {
	@include DisplayFlex;
	justify-content: space-between;
	width: 65rem;
	height: 3.5rem;
	margin-bottom: 50px;
	bottom: 0;
	//left:            33%;
	align-self: center;
	justify-self: center;
	position: fixed;
	left: 50%;
	transform: translate(-50%, 0);

	span {
		height: 100%;
		width: 20%;
		position: absolute;
		@include CenterWithDisplayFlex;
		font-family: MontserratMedium, sans-serif;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		/* identical to box height, or 22px */
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: #ffffff;
		cursor: pointer;
		text-align: center;
		font-size: 0.8rem;
	}

	span:hover {
		color: #000000;
		font-family: MontserratBold, sans-serif;
		font-weight: bold;
	}

	.menu-entrance {
		left: 0;
		z-index: 11;
	}

	.menu-international {
		left: 17%;
		z-index: 12;
		padding-left: 1.5rem;
		padding-right: 2rem;
		text-align: center;
	}

	.menu-national {
		z-index: 11;
		left: 34%;
		padding-left: 2rem;
		padding-right: 1.5rem;
	}

	.menu-ep2020 {
		left: 48%;
		z-index: 10;
		padding-left: 4rem;
		padding-right: 1rem;
	}

	.menu-recommendations {
		left: 65%;
		z-index: 9;
		padding-left: 0.5rem;
	}
}

.banner {
	//background-image: url("../../images/nav-banner.svg");
	//background-size:  100% 100%;
	//width:            221px;
	//height:           58px;
	position: fixed;
	@include CenterWithDisplayFlex;
	flex-direction: column;
	height: 80px;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 99;
	vertical-align: middle;
	padding-left: 100px;
	padding-right: 120px;

	.title {
		color: var(--font-color);
		font-size: 2rem;
		font-family: HACKED, HACKED-Cyr, sans-serif, sans-serif;
		text-align: center;
		vertical-align: middle;
	}
}

.go-to-main-page {
	margin-top: 5px;
	text-decoration: none;
	font-size: 2rem;
	font-family: HACKED, HACKED-Cyr, sans-serif;
	color: var(--font-color);

	:hover,
	:visited,
	:active {
		color: #ffffff;
	}
}

//.ep-menu-container {
//  .languages-links {
//    position: absolute;
//    right: -25rem;
//    bottom: 0;
//  }
//}
@media only screen and (max-width: 1600px) {
	.menu-page .menu-body {
		margin-top: 180px;
	}
	.menu-page .text-boxes {
		justify-content: flex-start;
	}
	.menu-page .right-box {
		margin-top: 0;
	}
	.menu-page .left-box .sub-page-title {
		font-size: 2.5rem;
	}
	.menu-page .left-box {
		height: 150px;
	}
}

@media only screen and (max-width: 1600px) and (max-height: 900px) {
	.menu-page .menu-body {
		margin-top: 180px;
	}
}

// TODO menu page 1600x 900

@media only screen and (max-width: 1536px) {
	.menu-page {
		.side-word {
			font-size: 5rem;
		}
	}
	.top-nav-wrapper {
		margin-top: 50px;
	}
	.five-navs {
		margin-bottom: 10px;
	}
	.nav-buttons {
		margin-bottom: 10px;
	}
	.side-button.left {
		left: 10% !important;
	}
	.side-button.right {
		right: 10% !important;
	}
}

@media screen and (max-width: 1440px) {
	.menu-page .menu-body {
		margin-top: 166px;
	}
}

@media only screen and (max-width: 1366px) {
	.menu-page .right-box.empty {
		background-image: none;
	}
	.menu-page .left-box {
		height: 130px;
	}
	.menu-page .upper-part {
		padding: 0;
	}
	.menu-bar {
		top: 10%;
	}
	.menu-page .menu-title {
		height: 5.5rem;
	}
	.menu-page .menu-body,
	.menu-page .text-boxes {
		//justify-content: flex-end;
	}
	.menu-page .side-word {
		font-size: 4rem;
	}
	.menu-page .menu-body {
		margin-bottom: 50px;
	}
	.menu-page {
		.menu-body {
			margin-top: 100px;
		}

		.side-button {
			//top: 20%;
			img {
				width: 2rem;
				height: 2rem;
			}
		}

		.side-button.left {
			left: 11% !important;
		}

		.side-button.right {
			right: 11% !important;
		}

		.small-desc {
			margin-top: 0;
		}
	}
	.top-nav-wrapper {
		//margin-top: 100px;
	}
	.menu-title {
		margin: 0;
	}
	.menu-bar {
		top: 12%;
	}
}

@media screen and (max-width: 1366px) and (min-height: 900px) {
	.menu-page .menu-body {
		justify-content: space-evenly;
	}
	.top-nav-wrapper {
		height: 96px;
	}
	.menu-bar {
		top: 10%;
		position: absolute;
	}
}

@media screen and (max-width: 1280px) {
	.top-nav-wrapper {
		height: 0;
	}
	.menu-bar {
		top: 10%;
		position: fixed;
	}
	.menu-page .right-box {
		background-image: none;
	}
	.menu-page .menu-body {
		justify-content: flex-end !important;
	}
	.menu-page .menu-body {
		margin-top: 150px;
		margin-bottom: 100px;
	}
	.top-nav-wrapper {
		position: absolute;
	}
	.menu-bar {
		top: 15%;
	}
	.menu-body {
		justify-content: flex-end;
	}
	.menu-page .upper-part {
		width: 60%;
	}
	.menu-page .small-desc {
		font-size: 1.5rem;
	}
	.menu-page .left-box .sub-page-title {
		font-size: 2rem;
	}
	.menu-page .left-box {
		height: 100px;
	}
	.banner {
		height: 60px;
	}
	.menu-page .upper-part {
		height: 70%;
	}
	.text-boxes {
		justify-content: space-between !important;
	}
	.five-navs,
	.nav-buttons {
		margin-bottom: 1%;
	}
}

@media only screen and (max-width: 1200px) {
	.top-nav-wrapper {
		margin-top: 200px;
	}
	.menu-title {
		margin: 0 !important;
	}
	.menu-body {
		margin-top: 0 !important;
	}
	.menu-bar {
		top: 120px;
	}
}

@media only screen and (max-width: 1200px) {
	.banner {
		height: 120px;
	}
	.five-navs,
	.nav-buttons {
		margin-bottom: 5%;
	}
	.menu-page .side-word {
		font-size: 6rem;
	}
	.menu-page .menu-title h1 {
		padding: 2rem !important;
	}
	.menu-page {
		height: 700px !important;
	}
	.menu-page {
		background-image: none;
		height: 100%;

		.five-navs {
			margin-left: 50px;
			margin-bottom: 50px;
		}

		.nav-title {
			padding-top: 1.2rem;
			padding-left: 3rem;
			width: 30rem;
			height: 4rem;
			font-size: 2rem;
		}

		.nav-title1 {
			bottom: -4rem;
			left: 3rem;
		}

		.nav-title2 {
			bottom: -4rem;
			left: 12rem;
		}

		.nav-title3 {
			bottom: -4rem;
			left: 20rem;
		}

		.menu-bar {
			top: 150px;
			justify-content: center;
			align-items: center;

			img {
				width: 7rem;
				height: 7rem;
			}
		}

		.menu-body {
			width: 80%;
			height: 60%;
			justify-content: flex-start !important;
			margin-top: 150px !important;
		}

		.menu-title {
			top: 10%;
			left: auto;
		}

		.side-button {
			display: none;
		}

		.upper-part {
			height: 40%;
			width: 80%;
		}

		.text-boxes {
			padding: 2rem;
			flex: 1;
		}

		.lower-part {
			margin-left: 0;
		}
	}
}

@media screen and (max-width: 1024px) {
	.menu-page .menu-body {
		justify-content: center !important;
		margin-top: 0 !important;
		height: 100%;
	}
}

@media screen and (max-width: 1024px) and (max-height: 768px) {
	.menu-page .right-box {
		background-image: none;
		//padding: 1rem 3rem 3rem !important;
		p {
			padding: 1rem 2rem;
			border: 1px solid #29e0e1;
		}
	}
	.menu-page .menu-body {
		justify-content: center !important;
	}
	.menu-page {
		.small-desc {
			margin-top: 100px;
		}
	}
	.dr-results {
		background-size: initial !important;
	}
	.box {
		margin-bottom: 3rem !important;
	}
}

@media screen and (max-width: 812px) and (max-height: 480px) {
	.menu-page .menu-body {
		margin-top: 60px !important;
	}
}

@media only screen and (max-width: 768px) {
	.menu-page .menu-body {
		margin-top: 150px !important;
	}
	.menu-body {
		margin-top: 150px !important;
	}
	.menu-page .upper-part {
		height: auto !important;
	}
	.menu-page .menu-title {
		height: 6.6rem !important;
	}
	.menu-page .right-box p {
		border: none;
	}
}
@media screen and (max-width: 700px) {
	.menu-bar {
		display: none;
	}
}

@media only screen and (max-width: 540px) {
	.banner {
		height: 90px !important;
	}
	.menu-page .menu-body {
		margin-top: 0 !important;
	}
	.container {
		margin-top: 0 !important;
	}
	.banner {
		@include DisplayFlex;
	}
	.menu-body {
		margin-top: 100px;
	}
	.top-nav-wrapper {
		display: none;
	}
	.nav-buttons {
		flex-wrap: wrap;
		position: static;
		transform: none;

		span {
			position: static;
			width: 100px;
		}
	}
	.five-navs {
		@include DisplayFlex;
		position: static;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		margin: 0 !important;
		width: 100%;
		transform: none;

		span:hover {
			color: #ffffff;
		}
	}
	.button-one {
		background-image: url("../../../assets/images/button-one-long.svg");
		color: #ffffff;
	}
	.button-same {
		background-image: url("../../../assets/images/button-nav-same.svg");
		color: #ffffff;
	}
	.button-one:hover {
		background-image: url("../../images/button-one-long.svg");
		color: #ffffff;
	}
	.button-same:hover {
		background-image: url("../../images/button-nav-same.svg");
		color: #ffffff;
	}
	.languages-links {
		height: 50px;
	}
	.menu-page {
		margin-top: 80px !important;
		justify-content: initial;

		.lower-part {
			width: 100%;
		}

		.nav-title1 {
			bottom: -4rem;
			left: 0;
		}

		.nav-title2 {
			bottom: -4rem;
			left: 0;
		}

		.nav-title3 {
			bottom: -4rem;
			left: 0;
		}

		.menu-title {
			width: 100%;

			h1 {
				font-size: 4rem;
			}
		}

		.languages-links {
			align-self: center;
		}

		.smaller-text {
			padding: 1rem 2rem;
			width: 100%;
			height: 50px;
			position: static !important;
		}

		.nav-buttons {
			justify-content: space-evenly;
			width: 100%;

			span {
				width: 100%;
				font-size: 1rem;
				margin-bottom: 1.5rem;
				height: 50px;
			}
		}

		.menu-bar {
			//top: 20%;
			img {
				width: 5rem;
				height: 5rem;
			}
		}

		.menu-body {
			height: 70%;
			width: 90%;
		}

		.text-boxes {
			width: 100%;
		}

		.left-box {
			width: 100%;

			.sub-page-title {
				font-size: 2rem;
			}

			margin-bottom: 2rem;
		}

		.right-box {
			margin: 0;
			width: 100%;
			padding: 0;
			background-size: 0;

			p {
				width: 100%;
				padding: 0;
			}
		}

		.small-desc {
			font-size: 1rem;
			margin: 0;
		}

		.languages-links {
			bottom: 0;
		}
	}
	.menu-page {
		.lower-part {
			flex-direction: column;
		}
	}
}

@media only screen and (max-width: 480px) {
	.menu-page {
		.menu-title {
			h1 {
				font-size: 2.5rem;
				padding-top: 2.5rem;
			}
		}

		.lower-part {
			flex-direction: column;
		}

		.languages-links {
			position: static;
		}
	}
	.nav-buttons {
		position: static;
	}
}

@media only screen and (max-width: 320px) {
	.menu-page {
		min-height: 100vh;

		.menu-title {
			//top: 1rem;
			h1 {
				//padding: ;
			}
		}

		.menu-bar {
			top: -4rem;

			img {
				width: 4rem;
				height: 4rem;
			}
		}

		.upper-part {
			height: auto;
		}

		.menu-body {
			margin-top: 1rem;
			height: 80%;
		}
	}
	.go-to-main-page,
	.banner .title {
		font-size: 1.5rem;
		text-align: center;
	}
	.menu-page .right-box p {
		border: none;
	}
}
