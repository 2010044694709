$path: "../../fonts";

@font-face {
  font-family: "HACKED";
  src:         local($path + "/hacked-font/HACKED"),
               url($path + "/hacked-font/HACKED.ttf") format("truetype");
  font-weight: 600;
  font-style:  normal;
}
@font-face {
  font-family: "HACKED-cyr";
  src:         local($path + "/hacked-font/Hacked-Cyr"),
               url($path + "/hacked-font/Hacked-Cyr.ttf") format("truetype");
  font-weight: 600;
  font-style:  normal;
}


@font-face {
  font-family: "MontserratBlack";
  src:         local($path + "/montserrat-font/Montserrat-Black"),
               url($path + "/montserrat-font/Montserrat-Black.ttf") format("truetype");
  font-weight: 500;
  font-style:  normal;
}

@font-face {
  font-family: "MontserratBlackItalic";
  src:         local($path + "/montserrat-font/Montserrat-BlackItalic"),
               url($path + "/montserrat-font/Montserrat-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style:  normal;
}

@font-face {
  font-family: "MontserratLightr";
  src:         local($path + "/montserrat-font/Montserrat-Light"),
               url($path + "/montserrat-font/Montserrat-Light.ttf") format("truetype");
  font-weight: 600;
  font-style:  normal;
}
@font-face {
  font-family: "MontserratBold";
  src:         local($path + "/montserrat-font/Montserrat-Bold"),
               url($path + "/montserrat-font/Montserrat-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style:  normal;
}
@font-face {
  font-family: "MontserratRegular";
  src:         local($path + "/montserrat-font/Montserrat-Regular"),
               url($path + "/montserrat-font/Montserrat-Regular.ttf") format("truetype");
  font-weight: 600;
  font-style:  normal;
}
@font-face {
  font-family: "MontserratLight";
  src:         local($path + "/montserrat-font/Montserrat-Light"),
               url($path + "/montserrat-font/Montserrat-Light.ttf") format("truetype");
  font-weight: 600;
  font-style:  normal;
}
body {
  font-family: "MontserratSemiBold", sans-serif;
}
