.da-container {
  margin-top: 40px;
  flex: 1;
  //min-height: 100vh;
  width: 100%;
  @include DisplayFlexCustom(column, flex-start, center);

  .text-body {
    @include DisplayFlexCustom(column, flex-start, center);
    position: relative;
    height: auto;
    width: 60%;
    padding: 2rem 6rem;
    margin: 3rem 0;
  }

  .box {
    margin-bottom: 1rem;
  }

  .side-title {
    position: absolute;
    writing-mode: vertical-rl;
    text-orientation: upright;
    font-family: HACKED, HACKED-Cyr, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 5rem;
    line-height: 100%;
    /* or 100px */
    letter-spacing: 0.05em;
    text-shadow: 2px 0 0 #29e0e1, -2px 0 0 #29e0e1, 0 2px 0 #29e0e1, 0 -2px 0 #29e0e1, 1px 1px #29e0e1, -1px -1px 0 #29e0e1, 1px -1px 0 #29e0e1, -1px 1px 0 #29e0e1;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #29e0e1;
  }

  .side-title.left {
    top: 5rem;
    left: 0;
  }

  .side-title.right {
    right: 0;
    bottom: 0;
  }

  .left-text {
    align-self: flex-start;
  }

  .right-text {
    align-self: flex-end;
  }

  h1 {
    align-self: flex-start;
    font-family: MontserratBold, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 3rem;
    line-height: 100%;
    /* identical to box height, or 50px */
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  h2 {
    font-family: MontserratBlack, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 3rem;
    line-height: 100%;
    /* identical to box height, or 50px */
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-shadow: 2px 0 0 var(--font-color), -2px 0 0 var(--font-color) 0 2px 0 var(--font-color), 0 -2px 0 var(--font-color), 1px 1px var(--font-color), -1px -1px 0 var(--font-color), 1px -1px 0 var(--font-color), -1px 1px 0 var(--font-color);
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #FFFFFF;
    align-self: flex-end;
    margin-bottom: 2rem;
  }
}

.da-container {
  flex: 1;
  //min-height: 100vh;
  width: 100%;
  @include DisplayFlexCustom(column, flex-start, center);

  .text-body {
    @include DisplayFlexCustom(column, flex-start, center);
    position: relative;
    height: auto;
    width: 60%;
    padding: 2rem 6rem;
    margin: 3rem 0;
  }

  .box {
    margin-bottom: 1rem;
  }

  .side-title {
    position: absolute;
    writing-mode: vertical-rl;
    text-orientation: upright;
    font-family: HACKED, HACKED-Cyr, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 5rem;
    line-height: 100%;
    /* or 100px */
    letter-spacing: 0.05em;
    text-shadow: 2px 0 0 #29e0e1, -2px 0 0 #29e0e1, 0 2px 0 #29e0e1, 0 -2px 0 #29e0e1, 1px 1px #29e0e1, -1px -1px 0 #29e0e1, 1px -1px 0 #29e0e1, -1px 1px 0 #29e0e1;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #29e0e1;
  }

  .side-title.left {
    top: 5rem;
    left: 0;
  }

  .side-title.right {
    right: 0;
    bottom: 30%;
  }

  .left-text {
    align-self: flex-start;
  }

  .right-text {
    align-self: flex-end;
  }

  h1 {
    align-self: flex-start;
    font-family: MontserratBold, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 3rem;
    line-height: 100%;
    /* identical to box height, or 50px */
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  h2 {
    font-family: MontserratBlack, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 3rem;
    line-height: 100%;
    /* identical to box height, or 50px */
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-shadow: 2px 0 0 var(--font-color), -2px 0 0 var(--font-color), 0 2px 0 var(--font-color), 0 -2px 0 var(--font-color), 1px 1px var(--font-color), -1px -1px 0 var(--font-color), 1px -1px 0 var(--font-color), -1px 1px 0 var(--font-color);
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #FFFFFF;
    align-self: flex-end;
    margin-bottom: 2rem;
  }

  .box {
    width: 60%;
    margin-bottom: 5rem;
    @include DisplayFlex;
    align-items: flex-end;
  }

  .right-side-img {
    position: absolute;
    top: 0;
    right: -50%;
  }

  .fileImg {
    margin: 0 2rem;
    width: 5rem;
  }

  .titles {
    width: 70%;
    align-self: flex-start;
    @include DisplayFlex;
    flex-direction: column;

    h1 {
      align-self: flex-start;
    }

    h2 {
      align-self: flex-end;
    }
  }

  .side-number {
    font-style: normal;
    font-weight: normal;
    font-size: 7rem;
    line-height: 100%;
    font-family: MontserratBlack, sans-serif;
    /* or 100px */
    letter-spacing: 0.05em;
    text-shadow: 2px 0 0 #29e0e1, -2px 0 0 #29e0e1, 0 2px 0 #29e0e1, 0 -2px 0 #29e0e1, 1px 1px #29e0e1, -1px -1px 0 #29e0e1, 1px -1px 0 #29e0e1, -1px 1px 0 #29e0e1;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #29e0e1;
    color: black;
  }

  h3, h4 {
    font-style: normal;
    font-family: MontserratBlack, sans-serif;
    font-weight: normal;
    font-size: 2rem;
    line-height: 100%;
    /* or 100px */
    letter-spacing: 0.05em;
    text-shadow: 0.5px 0 0 #29e0e1, -0.5px 0 0 #29e0e1, 0 0.5px 0 #29e0e1, 0 -0.5px 0 #29e0e1, 0.5px 0.5px #29e0e1, -0.5px -0.5px 0 #29e0e1, 0.5px -0.5px 0 #29e0e1, -0.5px 0.5px 0 #29e0e1;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: #29e0e1;
    color: black;
    align-self: flex-start;
    margin-bottom: 3rem;
  }

  .groupPeopleImg {
    width: 15rem;
    height: 15.7rem;
  }

  .personImg {
    width: 13rem;
    height: 15rem;
  }

  .squareImg, .square1Img {
    width: 5rem;
    height: 57rem;
  }

  .locationImg {
    width: 11rem;
    height: 16rem;
  }

  .unionImg {
    width: 22rem;
    height: 76rem;
  }

  .eyeImg {
    width: 14rem;
    height: 14rem;
  }

  .listImg {
    width: 12rem;
    height: 16rem;
  }

  .table {
    @include DisplayFlex;
    flex-direction: column;
    padding: 2rem 8rem;
  }

  .table-box {
    @include DisplayFlex;
    flex-direction: column;

  }

  .row {
    @include DisplayFlex;
    justify-content: space-between;

    span {
      font-size: 2rem;
      align-self: center;
      justify-self: center;
    }
  }

  .title {
    margin: 1rem 0;
    align-self: center;
    font-size: 3rem;
    font-style: normal;
    font-weight: normal;
    line-height: 100%;
    /* or 100px */
    letter-spacing: 0.05em;
    text-shadow: 1px 0 0 #29e0e1, -1px 0 0 #29e0e1, 0 1px 0 #29e0e1, 0 -1px 0 #29e0e1, 1px 1px #29e0e1, -1px -1px 0 #29e0e1, 1px -1px 0 #29e0e1, -1px 1px 0 #29e0e1;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #29e0e1;
  }

  .firstPart {
    @include DisplayFlex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 2rem;
    }
  }
}

///////////// entrance////////////
.da-entrance {
  .first-text {
    position: relative;
  }

  .box {
    width: 70%;
    margin-bottom: 5rem;
    @include DisplayFlex;
    align-items: flex-end;
  }

  .fourth-text, .sixth-text {
    align-items: flex-start;
  }

  img {
    margin: 0 3rem;
  }
}

///////////// narodniy ////////////
.da-narodniy {
  //background: url("../../images/personal-security/Group 37.svg") no-repeat center center;
  //-webkit-background-size: contain;
  //-moz-background-size: contain;
  //-o-background-size: contain;
  //background-size: contain;
  .first-text {
    position: relative;
  }

  h2 {
    margin-right: 30%;
  }

  .box {
    width: 60%;
  }

  li {
    list-style-type: none;
  }

  .box {
    @include DisplayFlex;
    margin-bottom: 2rem;
  }

  img {
    margin: 0 2rem;
  }

  .first-text, .third-text, .fifth-text, .seventh-text, .ninth-text, .second-text, .fourth-text, .sixth-text, .ninth-text {
    align-items: flex-end;
  }
}

///////////// nats ////////////////
.da-nationality {
  .box {
    width: 60%;
    @include DisplayFlex;
    align-items: flex-end;
    margin-bottom: 5rem;
  }

  h2 {
    margin-right: 30%;
  }

  .fileImg {
    margin: 0 2rem;
  }

  .locationImg {
    position: absolute;
    top: 10rem;
    right: 20%;
    //width:    11rem;
    //height:   12rem;
  }

  .squareImg {
    position: absolute;
    bottom: 5rem;
    left: -2rem;
  }
}

/////////// 2020 /////////////
.da-2020 {
  .box {
    width: 60%;
    @include DisplayFlex;
    align-items: flex-end;
    margin-bottom: 5rem;
  }

  .fileImg {
    margin: 0 2rem;
  }

  .table {
    background-image: url("../../images/personal-security/table-1.svg");
    background-size: 100% 100%;
    width: 50rem;
    height: 50rem;
  }

  .first-text {
    position: relative;
  }

  .eyeImg {
    position: absolute;
    top: 1rem;
    right: -50%;
  }

  .side-title.first, {
    top: 5%;
  }

  .side-title.second {
    top: 15%;
  }

  .side-title.third, {
    top: 30%;
  }

  .side-title.fourth {
    top: 45%
  }

  .side-title.fifth {
    top: 60%
  }

  .side-title.sixth {
    top: 75%
  }

  .side-title.seventh {
    top: 90%;
  }
}

//////////// recommendations /////////////
.da-recommendations {
  .box {
    width: 60%;
    @include DisplayFlex;
    margin-bottom: 5rem;
  }

  .side-number {
    writing-mode: vertical-rl;
    text-orientation: upright;
    font-style: normal;
    font-weight: normal;
    font-size: 5rem;
    line-height: 100%;
    font-family: MontserratBlack, sans-serif;
    /* or 100px */
    letter-spacing: 0.05em;
    text-shadow: 2px 0 0 #29e0e1, -2px 0 0 #29e0e1, 0 2px 0 #29e0e1, 0 -2px 0 #29e0e1, 1px 1px #29e0e1, -1px -1px 0 #29e0e1, 1px -1px 0 #29e0e1, -1px 1px 0 #29e0e1;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #29e0e1;
    color: black;
  }

  .square2 {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 5rem;
    height: 53rem;
  }

  .first-text {
    position: relative;
  }

  .listImg {
    //float: right;
    right: -50%;
    position: absolute;
    top: 0;
    width: 12rem;
    height: 15rem;
  }
}
@media screen and (max-width: 1200px){
  .da-container {
    margin-top: 80px;
  }
}
@media only screen and (max-width: 1024px) {

  .da-entrance, .da-recommendations {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    .text-body {
      width: 80%;
    }
  }
  .da-narodniy, .da-202, .da-recommendations {
    .box {
      width: 80%
    }
  }
  .da-nationality, .da-2020 {
    .text-body {
      width: 90%;
    }
  }
  .da-2020 {
    .eyeImg {
      //right: 10%;
    }
  }
  .da-recommendations {
    .listImg {
      right: -30%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .da-entrance, .da-narodniy, .da-nationality {
    .box {
      width: 80%;
    }
  }
  .da-nationality {
    .locationImg {
      top: 10rem;
      right: 2rem;
    }
  }
  .da-container{
    .text-body{
      width: 80%;
    }
  }
  .da-container {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 540px) {
  .da-container {
    margin-top: 20px;
  }
  .da-container {
    .right-side-img {
      position: static;
      float: right;
    }

    .fileImg {
      //display: ;
    }

    .fileImg {
      display: none;
    }

    .box {
      width: 100%;
      position: relative;
    }

    .side-title {
      display: none;
    }

    .text-body {
      padding: 2rem;
    }

    .titles {
      width: 100%;

      h1, h2 {
        align-self: flex-start;
      }
    }

    .squareImg, .unionImg {
      display: none;
    }

    h2 {
      margin-right: 0;
    }

  }

  .da-entrance, .da-narodniy {
    .box {
      position: relative;
    }

    img {
      //display: none;
      //position:   absolute;
      margin: 1rem;
      width: 36px;
      height: 24px;
      object-fit: contain;
    }

    .side-title {
      display: none;
    }

    .text-body {
      padding-top: 4rem;
    }

    //.first-text {
    //  img {
    //    bottom: 0;
    //    right:  0;
    //  }
    //}
    //
    //.fourth-text, .sixth-text {
    //  img {
    //    top:  0;
    //    left: 0;
    //  }
    //}
  }
  .da-narodniy {
    h1 {
      font-size: 2.5rem;
    }

    h2 {
      margin: 0;
      padding-bottom: 2rem;
      font-size: 2.5rem;
    }

    .text-body {
      width: 80%;
    }

    .box {
      width: 100%;
      //flex-direction: c;
    }
  }
  .da-nationality {
    .text-body {
      padding-top: 4rem;

    }

    .locationImg, {
      position: static;
      display: block;
      float: right;
    }

    .text-body {
      width: 90%;
    }

    .fileImg {
      position: static;
      width: 4rem;
      margin: 0 1rem;
    }

    .squareImg {
      width: 3rem;
      height: 50rem;
      left: 0;
    }

    .second-text, .fourth-text {
      flex-direction: row-reverse;
    }

    .side-title {
      display: none;
    }

    //.box{
    //  justify-content: flex-start;
    //}
  }
  .da-2020, .da-recommendations {
    .eyeImg {
      position: static;
      display: block;
      float: right;
      width: 10rem;
      height: 10rem;
    }

    .fileImg {
      position: absolute;
      right: 0;
      width: 4rem;
    }

    .table {
      width: 100%;
    }

    .text-body {
      width: 90%;
    }

    .box {
      width: 100%;
      align-self: flex-start !important;
    }
  }
  .da-2020 {
    .table {
      width: 100%;
      padding: 0;

      span {
        font-size: 2rem;
      }
    }

    .text-body {
      padding-top: 4rem;

    }
  }
  .da-recommendations {
    .side-title, .square2 {
      display: none;
    }

    .listImg {
      position: static;
      float: right;
    }

    .text-body {
      padding: 2rem;
    }

    .side-number {
      align-self: flex-start;
    }

    .text-body {
      padding-top: 4rem;

    }
  }
}

@media only screen and (max-width: 320px) {
  .da-nationality {
    h1, h2 {
      font-size: 2.5rem;
      text-align: center;
      margin: 0 auto;
    }

    h2 {
      padding-bottom: 3rem;
    }
  }
}
