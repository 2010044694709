@mixin DisplayFlex() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin DisplayGrid() {
  display: -ms-grid;
  display: grid;
}

@mixin Transition($time) {
  -webkit-transition: $time;
  -moz-transition: $time;
  -ms-transition: $time;
  -o-transition: $time;
  transition: $time;
}

@mixin CenterWithDisplayFlex() {
  @include DisplayFlex();
  align-items: center;
  justify-content: center;
}

@mixin ScrollBarCustom($trackWidth, $trackColor, $trackRadius, $backgroundColor) {
  &::-webkit-scrollbar {
    width: $trackWidth;
    background-color: $backgroundColor;
    padding-right: 25px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: $trackRadius;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $trackColor;
  }
}

@mixin PlaceHolder($color, $fontSize) {
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $color;
    font-size: $fontSize;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $color;
    font-size: $fontSize;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $color;
    font-size: $fontSize;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $color;
    font-size: $fontSize;
  }
}

@function toRem($value) {
  @return $value / $baseFontSize + rem;
}

@mixin FlexWrap($value) {
  -ms-flex-wrap: $value;
  -webkit-flex-wrap: $value;
  flex-wrap: $value;
}

@mixin Transform($value) {
  -webkit-transform: $value;
  -moz-transform: $value;
  -ms-transform: $value;
  -o-transform: $value;
  transform: $value;
}

@mixin BoxSizing() {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@mixin TextOverflowEllipsis() {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

@mixin MultilineTextOverflowEllipsis($font-size, $line-height, $lines-to-show) {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  height: $font-size*$line-height*$lines-to-show; /* Fallback for non-webkit */
  font-size: $font-size;
  line-height: $line-height;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin ResponsivePaddingLeft($value, $allowMinimum: false) {
  padding-left: $value;

  @media only screen and (max-width: 1200px) {
    padding-left: $value / 2;
  }
  @if ($allowMinimum == true) {
    @media only screen and (max-width: 1024px) {
      padding-left: $value / 3;
    }
  }
}

@mixin ResponsivePaddingRight($value, $allowMinimum: false) {
  padding-right: $value;
  @media only screen and (max-width: 1200px) {
    padding-right: $value / 2;
  }
  @if ($allowMinimum == true) {
    @media only screen and (max-width: 1024px) {
      padding-right: $value / 3;
    }
  }
}

@mixin ResponsivePaddingTop($value, $allowMinimum: false) {
  padding-top: $value;

  @media only screen and (max-width: 1200px) {
    padding-top: $value / 2;
  }
  @if ($allowMinimum == true) {
    @media only screen and (max-width: 1024px) {
      padding-top: $value / 3;
    }
  }
}

@mixin ResponsivePaddingBottom($value, $allowMinimum: false) {
  padding-bottom: $value;
  @media only screen and (max-width: 1200px) {
    padding-bottom: $value / 2;
  }
  @if ($allowMinimum == true) {
    @media only screen and (max-width: 1024px) {
      padding-bottom: $value / 3;
    }
  }
}

@mixin ResponsiveMarginLeft($value, $allowMinimum: false) {
  margin-left: $value;

  @media only screen and (max-width: 1200px) {
    margin-left: $value / 2;
  }
  @if ($allowMinimum == true) {
    @media only screen and (max-width: 1024px) {
      margin-left: $value / 3;
    }
  }
}

@mixin ResponsiveMarginRight($value, $allowMinimum: false) {
  margin-right: $value;
  @media only screen and (max-width: 1200px) {
    margin-right: $value / 2;
  }
  @if ($allowMinimum == true) {
    @media only screen and (max-width: 1024px) {
      margin-right: $value / 3;
    }
  }
}

@mixin ResponsiveMarginTop($value, $allowMinimum: false) {
  margin-top: $value;

  @media only screen and (max-width: 1200px) {
    margin-top: $value / 2;
  }
  @if ($allowMinimum == true) {
    @media only screen and (max-width: 1024px) {
      margin-top: $value / 3;
    }
  }
}

@mixin ResponsiveMarginBottom($value, $allowMinimum: false) {
  margin-bottom: $value;
  @media only screen and (max-width: 1200px) {
    margin-bottom: $value / 2;
  }
  @if ($allowMinimum == true) {
    @media only screen and (max-width: 1024px) {
      margin-bottom: $value / 3;
    }
  }
}

@mixin DeviceMarginTop($value) {
  margin-top: $value;
  @media only screen and (max-width: 960px) {
    margin-top: $value / 2;
  }
  @media only screen and (max-width: 768px) {
    margin-top: $value / 3;
  }
}

@mixin DeviceMarginBottom($value) {
  margin-bottom: $value;
  @media only screen and (max-width: 960px) {
    margin-bottom: $value / 2;
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: $value / 3;
  }
}

@mixin DevicePaddingTop($value) {
  padding-top: $value;
  @media only screen and (max-width: 960px) {
    padding-top: $value / 2;
  }
  @media only screen and (max-width: 768px) {
    padding-top: $value / 3;
  }
}

@mixin DevicePaddingBottom($value) {
  padding-bottom: $value;
  @media only screen and (max-width: 960px) {
    padding-bottom: $value / 2;
  }
  @media only screen and (max-width: 768px) {
    padding-bottom: $value / 3;
  }
}

@mixin DevicePaddingLeft($value) {
  padding-left: $value;
  @media only screen and (max-width: 960px) {
    padding-left: $value / 2;
  }
  @media only screen and (max-width: 768px) {
    padding-left: $value / 3;
  }
}

@mixin DevicePaddingRight($value) {
  padding-right: $value;
  @media only screen and (max-width: 960px) {
    padding-right: $value / 2;
  }
  @media only screen and (max-width: 768px) {
    padding-right: $value / 3;
  }
}

@mixin CustomTagA($linkState, $hoverState: null, $visitedState: null) {
  @if ($linkState != null) {
    &:link {
      color: $linkState
    }
  } @else {
    &:link {
      color: inherit
    }
  }
  @if ($hoverState != null) {
    &:hover {
      color: $hoverState
    }
  } @else {
    &:hover {
      color: $linkState
    }
  }
  @if ($hoverState != null) {
    &:visited {
      color: $visitedState
    }
  } @else {
    &:visited {
      color: $linkState
    }
  }
}

@mixin DisplayFlexCustom($direction: null, $justifyContent: null, $alignItems: null) {
  @include DisplayFlex();
  @if ($direction != null) {
    flex-direction: $direction;
  } @else {
    flex-direction: initial;
  }
  @if ($justifyContent != null) {
    justify-content: $justifyContent;
  } @else {
    justify-content: initial;
  }
  @if ($alignItems != null) {
    align-items: $alignItems;
  } @else {
    align-items: initial;
  }
}

@mixin GridTemplateTwoCols($firstCol: 1fr, $secondCol: 1fr, $placeItems: null) {
  @include DisplayGrid();
  -ms-grid-columns: $firstCol $secondCol;
  grid-template-columns: $firstCol $secondCol;
  @if ($placeItems != null) {
    place-items: $placeItems;;
  } @else {
    place-items: initial;
  }
}

@mixin GridTemplateThreeCols($firstCol: 1fr, $secondCol: 1fr,$thirdCol: 1fr, $placeItems: null) {
  @include DisplayGrid();
  -ms-grid-columns: $firstCol $secondCol $thirdCol;
  grid-template-columns: $firstCol $secondCol $thirdCol;
  @if ($placeItems != null) {
    place-items: $placeItems;
  } @else {
    place-items: initial;
  }
}

@mixin AbsolutePositioning() {
  position: absolute;
  left: 50%;
  top: 50%;
  @include Transform(translate(-50%, -50%));
}

@mixin IsolatedSection() {
  float: left;
  width: 100%;
}

@mixin Border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin BackgroundSize($size) {
  -webkit-background-size: $size;
  background-size: $size;
}

@mixin Transition($time) {
  -webkit-transition: all $time linear;
  -moz-transition: all $time linear;
  -ms-transition: all $time linear;
  -o-transition: all $time linear;
  transition: all $time linear;
}

@mixin Box-shadow($color, $thickness) {
  -webkit-box-shadow: -$thickness -$thickness 0 0 $color,
  $thickness $thickness 0 0 $color,
  $thickness $thickness 0 0 $color,
  -$thickness $thickness 0 0 $color;

  -moz-box-shadow: -$thickness -$thickness 0 0 $color,
  $thickness $thickness 0 0 $color,
  $thickness -$thickness 0 0 $color,
  -$thickness $thickness 0 0 $color;

  box-shadow: -4px -4px 0 0 $color,
  4px 4px 0 0 $color,
  -4px 4px 0 0 $color,
  4px -4px 0 0 $color;
}

@mixin Box-shadow-universal($inset, $hoff, $voff, $blur, $color) {
  -webkit-box-shadow: $inset $hoff $voff $blur $color;
  -moz-box-shadow: $inset $hoff $voff $blur $color;
  box-shadow: $inset $hoff $voff $blur $color;
}

@mixin Box-shadow-single($value) {
  -webkit-box-shadow: $value;
  -moz-box-shadow: $value;
  box-shadow: $value;
}

@mixin DisableBoxShadow() {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

@mixin Field() {
  border: 1px solid #ACB6C3;
  @include PlaceHolder(#6B7683, toRem(16));
  @include Transition(.2s);
  @include Border-radius(3px);
  &:hover {
    @include Box-shadow(#EAEDF8, 4px);
    border: 1px solid #A8A8AC;
  }
  &:focus {
    @include Box-shadow(#CCEEFF, 4px);
    border: 1px solid #00AAFF;
  }
  &:disabled {
    color: #ACB5BA;
    background-color: #F3F7FA;
    @include DisableBoxShadow();
  }
}

@mixin HoveredCheckbox() {
  @include Box-shadow(#EAEDF8, 4px);
  border: 1px solid #A8A8AC;
}

@mixin TransformY() {
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin SelectedCheckbox() {
  @include Box-shadow(#CCEEFF, 4px);
  border: 1px solid #00AAFF;
}

@mixin FieldWrapper() {
  position: relative;
  width: 100%;
}

@mixin FieldLogo() {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 17px;
}

@mixin TextareaLogo() {
  display: block;
  position: absolute;
  top: 17px;
  right: 17px;
}

@mixin LPBtn() {
  height: 40px;
  background-color: $brandRed;
  color: #fff;
  font-size: toRem(16);
  outline: none;
  @include DevicePaddingLeft(30px);
  @include DevicePaddingRight(30px);
}

@mixin LpFilterSmallBtn() {
  max-width: 33px;
  min-width: 33px;
  height: 33px;
  @include Border-radius(2px);
  @include DisplayFlex();
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@mixin CustomScrollBar($thumbColor, $trackColor) {
  &::-webkit-scrollbar {
    width: 2px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: $thumbColor;
  }

  &::-webkit-scrollbar-track {
    background: $trackColor;
  }
}


