.main-page {
  background:              url("../../images/home-page/mainPage.svg") no-repeat center center;
  //object-fit:              cover;
  -webkit-background-size: contain;
  -moz-background-size:    contain;
  -o-background-size:      contain;
  background-size:         contain;
  @include DisplayFlexCustom(column, center, center);
  flex:                    1;
  height:                  100vh;
  width:                   100%;

  .link {
    text-decoration: none;
    @include DisplayFlex;
    align-items:     center;
    justify-content: center;
    position:        relative;
    z-index:         100;

    span {
      font-family:    MontserratSemiBold, sans-serif;
      font-style:     normal;
      font-weight:    600;
      width:          100%;
      height:         100%;
      color:          #000000;
      text-align:     center;
      vertical-align: middle;
      margin:         0 auto;
    }
  }

  a:hover, link:active, link:visited {
    color: #000000;
  }

  &__title {
    position:        relative;
    @include DisplayFlex;
    width:           70%;
    height:          70%;
    align-self:      center;
    justify-content: center;
    align-items:     center;

    h1 {
      top:            35%;
      left:           50%;
      transform:      translate(-50%, 0);
      position:       absolute;
      font-family:    HACKED;
      font-style:     normal;
      font-weight:    normal;
      font-size:      10rem;
      line-height:    80%;
      text-align:     center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color:          #ffffff;
      z-index:        10;
      width:          80%;
      margin-left:    0.5rem;
    }

    p {
      top:            35%;
      width:          80%;
      left:           50%;
      transform:      translate(-50%, 0);
      margin-left:    0;
      position:       absolute;
      //padding-right:  5rem;
      font-family:    HACKED;
      font-style:     normal;
      font-weight:    normal;
      font-size:      10rem;
      line-height:    80%;
      text-align:     center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color:          #56f5ff;
      text-shadow:    1rem 0px #ff5656;
    }

    .small-desc {
      //position:       absolute;
      bottom:         35%;
      left:           10%;
      font-family:    MontserratRegular, sans-serif;
      font-style:     normal;
      font-weight:    normal;
      font-size:      2rem;
      line-height:    150%;
      /* identical to box height, or 52px */
      letter-spacing: 0.05em;
      color:          #ffffff;
      margin-top:     20rem;
      text-align:     center;

      img {
        width:        1.5rem;
        height:       1.5rem;
        margin-right: 0.5rem;
      }
    }
  }

  .info-access {
    position:         absolute;
    cursor:           pointer;
    top:              10%;
    left:             18%;
    background-image: url("../../images/home-page/links-boxes/info-access.svg");
    background-size:  100% 100%;

    span {
      //padding-left: 1rem;
      //padding: 0 1rem;
      padding:       2rem;
      margin-bottom: 1.5rem;
    }
  }

  .personal-security {
    background-image: url("../../images/home-page/links-boxes/new/personal-security.svg");
    background-size:  cover;
    position:         absolute;
    cursor:           pointer;
    padding-left:     1rem;
    padding-right:    3rem;
    left:             37%;
    top:              5%;
    font-size:        1.5rem;

    .rectangle.right {
      //width: 60px;
      //height:60px;
      top:   -20%;
      right: -20%;
    }

    span {
      //padding-left: 1rem;
      margin-bottom: 10px;
    }
  }

  .digital-privacy {
    background-image: url("../../images/home-page/links-boxes/digital-rights.svg");
    background-size:  100% 100%;
    position:         absolute;
    cursor:           pointer;
    padding:          0.8rem;
    right:            25%;
    top:              18%;

    span {
      //padding-left: 1rem;
      padding:       2.2rem;
      margin-bottom: 1.2rem;
    }
  }

  .new-calls {
    background-image: url("../../images/home-page/links-boxes/new-calls.svg");
    background-size:  100% 100%;
    position:         absolute;
    cursor:           pointer;
    padding:          0.8rem;
    left:             15%;
    bottom:           15%;

    span {
      //padding-left: 2.5rem;
      padding:       1.7rem;
      margin-bottom: 1.3rem;
    }
  }

  .electronic-participation {
    background-image: url("../../images/home-page/links-boxes/electronic-participation.svg");
    background-size:  100% 100%;
    position:         absolute;
    cursor:           pointer;
    padding:          0.8rem;
    left:             25%;
    bottom:           0%;

    span {
      //padding-left: 2.5rem;
      padding:       1.5rem;
      margin-bottom: 1.5rem;
    }
  }

  .limitation-on-internet-content {
    background-image: url("../../images/home-page/links-boxes/limit-on-internet.svg");
    background-size:  100% 100%;
    position:         absolute;
    cursor:           pointer;
    padding:          1rem;
    right:            40%;
    bottom:           7%;

    span {
      //padding-left: 2.5rem;
      padding:       2rem;
      margin-bottom: 1.3rem;;
    }
  }

  .freedom-expression {
    background-image: url("../../images/home-page/links-boxes/digital-rights.svg");
    background-size:  100% 100%;
    position:         absolute;
    cursor:           pointer;
    padding:          0.8rem;
    right:            20%;
    bottom:           20%;

    span {
      //padding-left: 2.5rem;
      padding:       1.5rem;
      margin-bottom: 1.5rem;
    }
  }

  .rights-to-fair-trial {
    background-image: url("../../images/home-page/links-boxes/info-access.svg");
    position:         absolute;
    cursor:           pointer;
    padding:          1.2rem;
    right:            35%;
    top:              12%;
    background-size:  100% 100%;

    span {
      //padding-left: 1rem;
      //padding: 0 1rem;
      padding:       1.5rem;
      margin-bottom: 1.2rem;
    }
  }

  .rectangle {
    position: absolute;
    top:      25%;
  }

  .rectangle.right {
    right: -1rem;
  }

  .rectangle.left {
    left: -1rem;
  }
}

@media only screen and (max-width: 1680px) {
  .main-page__title .small-desc {
    margin-top: 23rem;
  }
  .main-page__title .new-calls {
    left:   12%;
    bottom: 5%;
  }
  .main-page {
    .freedom-expression {
      right:  10%;
      bottom: 10% !important;
    }
    .limitation-on-internet-content{
      bottom: 0;
    }
    .personal-security{
      left:30%;
    }
    .rights-to-fair-trial{
      right: 30% !important;
    }

  }
}

@media only screen and (max-width: 1600px) {
  .main-page__title h1, .main-page__title p {
    font-size: 7rem;
  }
  .main-page__title p {
    //left: 5rem;
  }
  .main-page__title {
    width: 70%;
  }
  .main-page__title .small-desc {
    font-size:  1.5rem;
    margin-top: 12rem;
  }
  .main-page__title h1, .main-page__title p {
    //width: 100%;
    //padding: 0 !important;
  }
  .main-page {
    .limitation-on-internet-content {
      bottom: 0;
    }

    .electronic-participation {
      left: 30%;
    }

    .digital-privacy {
      right: 20%;
      top:   5%;
    }

    .info-access {
      top:  10%;
      left: 14%;
    }
  }
}

@media only screen and (max-width: 1536px) {
  .main-page {
    &__title {
      //width: 80%;
    }
  }
}

@media only screen and (max-width: 1366px) {
  .main-page {
    .rectangle {
      top: 20%;
    }

    .rectangle.right {
      right: -2rem;
    }

    .rectangle.left {
      left: -2rem;
    }
  }
  .main-page__title {
    width: 60%;
  }
  .main-page .digital-privacy {
    right: 10%;
  }
  .main-page .rights-to-fair-trial {
    right: 25%;
    top:   15%;
  }
  .main-page  .limitation-on-internet-content{
    bottom: 5%;
  }
  .main-page .freedom-expression{
    bottom: 8% !important;
  }
}

@media screen and (max-width: 1366px) and (max-height: 1024px) {
  .main-page {
    .new-calls {
      bottom: 20%;
    }

    .electronic-participation {
      bottom: 10%;
    }

    .freedom-expression {
      bottom: 20% !important;
    }
  }
}

@media screen and (max-width: 1360px) {
  .main-page .limitation-on-internet-content {
    bottom: 0 !important;
  }
}

@media screen and (max-width: 1280px) {
  .main-page {
    .new-calls {
      bottom: 15%;
    }

    .electronic-participation {
      bottom: 5%;
    }

    .freedom-expression {
      bottom: 15% !important;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .main-page__title p,
  .main-page__title h1 {
    top: 47%;
  }
  .main-page .rights-to-fair-trial {
    right: 50%;
  }
  .main-page__title .small-desc {
    width: 80%;
  }
  .main-page {
    &__title {
      width: 100%;
    }

    .info-access {
      left: 20%;
      top:  25%;
    }

    .new-calls {
      bottom: 30%;
    }

    .electronic-participation {
      bottom: 20%;
    }

    .freedom-expression {
      bottom: 15%;
    }

    .limitation-on-internet-content {
      bottom: 30% !important;
    }

    .personal-security {
      left:  65%;
      width: 180px;
      top:   30%;
    }

    .digital-privacy {
      right: 30%;
      top:   20%;
    }

    .rights-to-fair-trial {
      right: 50% !important;
    }

    .digital-privacy {
      right: 10% !important;
    }

    .personal-security {
      left: 50% !important;
    }
  }
}

@media screen and (max-width: 1024px) and (max-height: 768px) {
  .container {
    margin: 0 !important;
  }
  .main-page {
    .new-calls {
      bottom: 20%;
    }

    .electronic-participation {
      bottom: 10%;
    }

    .limitation-on-internet-content {
      bottom: 10% !important;
      right:  10% !important;
    }

    .freedom-expression {
      right: 30% !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .main-page {
    h1, p {
      font-size: 6rem;
    }
  }
}

@media only screen and (max-width: 812px) {
  .banner {
    height: 70px !important;
  }
  .main-page__title h1 {
    transform: none;
  }
  .menu-page .menu-title h1 {
    padding-top: 2rem !important;
  }
  .menu-page .upper-part, .menu-page .left-box {
    height: auto !important;
  }
  .menu-body {
    height: auto !important;
  }
  .main-page {
    background-image: url("../../images/home-page/mob-back.svg");
    padding:          2rem;
    box-sizing:       border-box;
    justify-content:  flex-start;
    align-items:      flex-start;

    .small-desc {
      //display: none;
      font-size:   2rem;
      font-weight: 600;
    }

    .main-page__title {
      height:         auto;
      flex-direction: column;
      align-items:    flex-start;
      padding:        1rem 2rem;
      margin-top:     50px;

      h1 {
        position:       static;
        font-family:    HACKED, sans-serif;
        font-style:     normal;
        font-weight:    normal;
        font-size:      4rem;
        line-height:    80%;
        letter-spacing: 0.05em;
        margin-bottom:  2rem;
        text-align:     left;
      }

      p {
        display: none;
      }
    }

    .info-access {
      background-image: url("../../images/home-page/links-boxes/new/info-access.svg");
    }

    .digital-privacy {
      background-image: url("../../images/home-page/links-boxes/new/digital-rights.svg");
    }

    .personal-security {
      background-image: url("../../images/home-page/links-boxes/new/personal-security.svg");
    }

    .new-calls {
      background-image: url("../../images/home-page/links-boxes/new/new-calls.svg");
    }

    .electronic-participation {
      background-image: url("../../images/home-page/links-boxes/new/electronic-participation.svg");
    }

    .freedom-expression {
      background-image: url("../../images/home-page/links-boxes/new/freedom-expression.svg");
    }

    .limitation-on-internet-content {
      background-image: url("../../images/home-page/links-boxes/new/limit-on-internet.svg");
    }

    .rights-to-fair-trial {
      background-image: url("../../images/home-page/links-boxes/new/freedom-expression.svg");
    }

    .main-page__links {
      margin:          20px 0;
      position:        static;
      width:           100%;
      justify-content: space-between !important;
      //align-items: space-between;
      @include DisplayFlexCustom(column, center, flex-start);
      height:          530px;
    }

    .box {
      position:        static !important;
      width:           450px;
      background-size: cover;
      height:          70px;
    }

    .small-desc {
      margin:         0;
      font-family:    MontserratRegular, sans-serif;
      font-size:      1.5rem;
      line-height:    120%;
      text-align:     left;
      /* or 14px */
      letter-spacing: 0.05em;
    }

    a {
      span {
        font-size:  2rem;
        text-align: left !important;
        padding:    0 !important;
      }
    }

    .personal-security, .new-calls, .electronic-participation, .limitation-on-internet-content, .freedom-expression {
      padding-top: 1.5rem;
    }

    .link {
      position:        static !important;
      padding:         0 !important;
      background-size: cover;
      height:          40px;
      width:           80%;
      max-width:       400px;

      span {
        position:      relative;
        display:       flex;
        margin:        0 !important;
        text-align:    center;
        width:         100%;
        height:        100%;
        padding:       0 2rem !important;
        margin-bottom: 10px;
      }
    }

    .rectangle {
      width:  60px;
      height: 60px;
    }

    .rectangle.right {
      right: -4rem !important;
      top:   -1rem !important;
    }

    .rectangle.left {
      right:  -4rem;
      top:    -1rem;
      left:   auto;
      bottom: auto;
    }

    .link {
      min-height: 43px !important;

      span {
        background:    #ffffff;
        height:        auto;
        margin-bottom: 2rem !important;
        padding:       0 !important;
        padding-left:  2rem !important;
        padding-right: 2rem !important;
        align-self:    flex-start;
      }
    }
  }
  .App {
    height: auto;
  }
  .container {
    height: auto;
  }
}

@media only screen and (max-width: 480px) {
  .main-page {
    .main-page__links {
    }

    .box {
      width:        330px;
      height:       50px;
      padding-left: 2rem;
    }

    .personal-security, .new-calls, .electronic-participation, .limitation-on-internet-content, .freedom-expression {
      padding-top: 1rem;
    }

    a {
      span {
        font-size: 1.8rem;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .main-page .link {
    width: 220px;
  }
  .main-page .main-page__links {
    height: 600px;
  }
  .main-page .main-page__title {
    padding: 0;
  }
}

//
//@media only screen and (max-width: 540px) {
//  .main-page {
//    h1 {
//      font-size: 7rem;
//    }
//
//    p {
//      font-size: 7rem;
//    }
//
//    .box {
//      //position: static;
//    }
//
//    .small-desc {
//      font-size: 1.5rem;
//    }
//
//    .info-access {
//      left: 15%;
//      top:  25%;
//    }
//
//    .digital-rights {
//      right: 15%;
//      top:   30%;
//    }
//
//    .digital-privacy {
//      right: 30%;
//      top:   20%;
//    }
//
//    .new-calls {
//      left:   20%;
//      bottom: 25%;
//    }
//
//    .freedom-expression {
//      right: 15%;
//    }
//
//    .limitation-on-internet-content {
//      right:  15%;
//      bottom: 20%;
//    }
//
//    .electronic-participation {
//      left:   25%;
//      bottom: 15%;
//    }
//  }
//}
//
//@media only screen and (max-width: 480px) {
//  .main-page {
//    .digital-rights {
//      right: 15%;
//      top:   30%;
//    }
//
//    .info-access {
//      left: 15%;
//      top:  25%;
//    }
//
//    .digital-privacy {
//      right: 20%;
//      top:   20%;
//    }
//  }
//}
//
//@media only screen and (max-width: 280px) {
//  .main-page {
//    .freedom-expression {
//      right: 0;
//    }
//
//    .limitation-on-internet-content {
//      right: 0;
//    }
//    h1{
//      font-size: 6rem;
//    }
//    p{
//      font-size: 6rem;
//    }
//  }
//}
